import * as tslib_1 from "tslib";
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { Assets } from './libs/3d-cube/Assets';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
function main() {
    return tslib_1.__awaiter(this, void 0, void 0, function* () {
        window.assets = yield Assets.LoadDDAllAssets();
        __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory);
    });
}
main().catch(err => console.error(err));
localStorage.debug = '*:*';
// localStorage.debug = 'sk-ble:cubeState';
