import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BleDeviceInfo, BleStateService } from 'src/app/services/ble-state.service';
import { BleService } from 'src/app/services/ble.service';
import { scan } from 'rxjs/operators';


export interface BleDialogData {
  namePrefix: string
}

@Component({
  selector: 'app-ble-dialog',
  templateUrl: './ble-dialog.component.html',
  styleUrls: ['./ble-dialog.component.scss'],
})
export class BleDialogComponent implements OnInit {
  namePrefix: string;
  bleScan$: Observable<BleDeviceInfo[]>;

  constructor(
    public dialogRef: MatDialogRef<BleDialogComponent>,
    public bleService: BleService,
    public bleStateService: BleStateService,
    @Inject(MAT_DIALOG_DATA) bleDialogData: BleDialogData) {
    this.namePrefix = bleDialogData.namePrefix
  }

  ngOnInit() {
    this.startScan()
  }

  async startScan() {
    const { isEnabled, hasPermission, isLocationEnabled } = await this.bleService.requestPermission()
    if (isEnabled && hasPermission && isLocationEnabled) {
      this.bleScan$ = (await this.bleService.startScan('MHC')).pipe(
        scan(
          (acc, curr) => [...acc, curr],
          new Array<BleDeviceInfo>()
        )
      )
    } else {
      console.log('蓝牙未启动')
    }
  }

  connect(device: BleDeviceInfo) {
    this.dialogRef.close(device)
  }

  close() {
    this.dialogRef.close(null)
  }
}
