import * as tslib_1 from "tslib";
import { Buffer } from 'buffer';
export function sleep(ms) {
    return tslib_1.__awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, ms);
        });
    });
}
export function hexToBuffer(str) {
    return Buffer.from(str.trim().split(' ').map(i => parseInt(i, 16)));
}
export function bufferToHex(buff) {
    return Array.from(buff).map(i => i.toString(16)).map(i => i.length === 1 ? '0' + i : i).join(' ');
}
