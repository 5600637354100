import * as tslib_1 from "tslib";
import { BleNativeService } from './ble-native.service';
import { BleStateService } from './ble-state.service';
import { BleBrowserService } from './ble-browser.service';
import { Debug } from 'src/libs/debug';
import { bufferToHex } from 'src/libs/utils';
import { Platform } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "./ble-state.service";
import * as i2 from "@ionic/angular";
import * as i3 from "./ble-native.service";
import * as i4 from "./ble-browser.service";
export class BleService {
    constructor(bleStateService, platform, bleNativeService, bleBrowserService) {
        this.bleStateService = bleStateService;
        if (platform.is('cordova')) {
            console.log("在 cordova 环境中，使用 bleNativeService");
            this.ble = bleNativeService;
        }
        else {
            console.log("在浏览器环境中，使用 bleBrowserService");
            this.ble = bleBrowserService;
        }
    }
    checkPermission() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.ble.checkPermission();
        });
    }
    requestPermission() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.ble.requestPermission();
        });
    }
    startScan(startWith, scanTime = 10000) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.scanningTimer = setTimeout(() => {
                this.stopScan();
            }, scanTime);
            this.flushTimer = setInterval(() => {
                this.bleStateService.flush++;
            }, 1000);
            return yield this.ble.startScan(startWith);
        });
    }
    stopScan() {
        clearTimeout(this.scanningTimer);
        clearInterval(this.flushTimer);
        return this.ble.stopScan();
    }
    connect(deviceId, enableDebug) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.ble.connect(deviceId, enableDebug);
        });
    }
    get command$() {
        return this.ble.command$;
    }
    get rotate$() {
        return this.ble.rotate$;
    }
    get attitude$() {
        return this.ble.attitude$;
    }
    /**
     * 返回是否已经连接设备
     */
    get connected() {
        return this.bleStateService.connected;
    }
    disconnect(deviceId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // 提前保存好上一次连接的信息
            this.bleStateService.prevConnectedDevice = this.bleStateService.connectedDevice;
            return this.ble.disconnect(deviceId);
        });
    }
    send(buff) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            Debug.send(bufferToHex(buff));
            return this.ble.send(buff);
        });
    }
    get otaProgress$() {
        return this.ble.otaProgress$;
    }
    ota(otaFileBuffer, mtu, prn) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.ble.ota(otaFileBuffer, mtu, prn);
        });
    }
    requestMtu(mtuSize) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.ble.requestMtu(mtuSize);
        });
    }
}
BleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BleService_Factory() { return new BleService(i0.ɵɵinject(i1.BleStateService), i0.ɵɵinject(i2.Platform), i0.ɵɵinject(i3.BleNativeService), i0.ɵɵinject(i4.BleBrowserService)); }, token: BleService, providedIn: "root" });
