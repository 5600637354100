import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader';
import { debuglog } from 'util';
import { AnyCubeController } from './MagicCubeAny/AnyCubeController';
import { FillColorCubeController } from './MagicCubeAny/FillColorScene/FillColorCubeController';
import { AnyCubeFactory } from './MagicCubeAny/AnyCubeFactory';
import { Debug } from './Util/Debug';
import { Tools } from './Util/Tools';
import { Axis } from './GLObject';
import { CubeTexture, Matrix4 } from 'three';

const textureLoader = new THREE.TextureLoader();
export async function loadTexture(url: string): Promise<THREE.Texture> {
    // return textureLoader.load(url);
    return new Promise<THREE.Texture>((resolve, reject) => {
        resolve(textureLoader.load(url));
    })
}

export const fbxLoader = new FBXLoader();

export const objLoader = new OBJLoader();

export class FaceTexturesPackage implements FaceTexturesHandle {
    public TexRed: THREE.Texture;
    public TexYellow: THREE.Texture;
    public TexGreen: THREE.Texture;
    public TexBlue: THREE.Texture;
    public TexOrange: THREE.Texture;
    public TexWhite: THREE.Texture;
    public TexBlack: THREE.Texture;

    GetRed(): THREE.Texture {
        return this.TexRed;
    }
    GetYellow(): THREE.Texture {
        return this.TexYellow;
    }
    GetGreen(): THREE.Texture {
        return this.TexGreen;
    }
    GetBlue(): THREE.Texture {
        return this.TexBlue;
    }
    GetOrange(): THREE.Texture {
        return this.TexOrange;
    }
    GetWhite(): THREE.Texture {
        return this.TexWhite;
    }
    GetBlack(): THREE.Texture {
        return this.TexBlack;
    }

    dispose() {
        this.TexYellow.dispose();
        this.TexRed.dispose();
        this.TexWhite.dispose();
        this.TexOrange.dispose();
        this.TexGreen.dispose();
        this.TexBlue.dispose();
    }
}

export interface FaceTexturesHandle {
    GetRed(): THREE.Texture;
    GetYellow(): THREE.Texture;
    GetGreen(): THREE.Texture;
    GetBlue(): THREE.Texture;
    GetOrange(): THREE.Texture;
    GetWhite(): THREE.Texture;
    GetBlack(): THREE.Texture;
}

export abstract class Assets implements AnyCubeFactory {
    protected disposeOperations: Array<() => void>;

    protected constructor() {
        this.disposeOperations = Array<() => void>();
    }

    public static async LoadGDTZAllAssets(): Promise<Assets> {
        const assets = new GDTZAssets();
        await assets.loadAllModelsAndTextures();
        await assets.generateMaterials();
        await assets.generateFaceMeshes();
        return assets;
    }
    public static async LoadDDAllAssets(): Promise<Assets> {
        const assets = new DDAssets();
        await assets.loadAllModelsAndTextures();
        await assets.generateMaterials();
        await assets.generateFaceMeshes();
        return assets;
    }
    public static async LoadGDAllAssets(): Promise<Assets> {
        const assets = new GDAssets();
        await assets.loadAllModelsAndTextures();
        await assets.generateMaterials();
        await assets.generateFaceMeshes();
        return assets;
    }

    public abstract GetBeamGeo(): THREE.BufferGeometry | THREE.Geometry;

    // public abstract GetAxisArrowGeo(): THREE.BufferGeometry | THREE.Geometry;

    public abstract GetAxisArrowY0Geo(): THREE.BufferGeometry | THREE.Geometry;

    public abstract GetAxisArrowY1Geo(): THREE.BufferGeometry | THREE.Geometry;

    public abstract GetAxisArrowZ0Geo(): THREE.BufferGeometry | THREE.Geometry;

    public abstract GetAxisArrowZ1Geo(): THREE.BufferGeometry | THREE.Geometry;

    public abstract GetAxisArrowX0Geo(): THREE.BufferGeometry | THREE.Geometry;

    public abstract GetAxisArrowX1Geo(): THREE.BufferGeometry | THREE.Geometry;

    public abstract GenerateCube(position: THREE.Vector3, step: number): AnyCubeController;

    public abstract GenerateFillColorCube(position: THREE.Vector3, step: number): FillColorCubeController;

    public abstract AddStaticMeshToCube(cube: THREE.Group): void;

    public dispose() {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.disposeOperations.length; i++) {
            this.disposeOperations[i]();
        }
    }
}

enum CubeType {
    U = 100,
    D = 200,
    L = 10,
    R = 20,
    F = 1,
    B = 2,
    UL = CubeType.U + CubeType.L,
    UR = CubeType.U + CubeType.R,
    UB = CubeType.U + CubeType.B,
    UF = CubeType.U + CubeType.F,
    DL = CubeType.D + CubeType.L,
    DR = CubeType.D + CubeType.R,
    DF = CubeType.D + CubeType.F,
    DB = CubeType.D + CubeType.B,
    LF = CubeType.L + CubeType.F,
    LB = CubeType.L + CubeType.B,
    RF = CubeType.R + CubeType.F,
    RB = CubeType.R + CubeType.B,
    ULF = CubeType.UL + CubeType.F,
    ULB = CubeType.UL + CubeType.B,
    URF = CubeType.UR + CubeType.F,
    URB = CubeType.UR + CubeType.B,
    DLF = CubeType.DL + CubeType.F,
    DLB = CubeType.DL + CubeType.B,
    DRF = CubeType.DR + CubeType.F,
    DRB = CubeType.DR + CubeType.B
}

export class GDAssets extends Assets {
    public AxisArrowY0: THREE.BufferGeometry | THREE.Geometry;
    public AxisArrowY1: THREE.BufferGeometry | THREE.Geometry;
    public AxisArrowZ0: THREE.BufferGeometry | THREE.Geometry;
    public AxisArrowZ1: THREE.BufferGeometry | THREE.Geometry;
    public AxisArrowX0: THREE.BufferGeometry | THREE.Geometry;
    public AxisArrowX1: THREE.BufferGeometry | THREE.Geometry;
    public BeamGeo: THREE.BufferGeometry | THREE.Geometry;
    public OriginWholeCube: THREE.Group;

    public FaceTextures: FaceTexturesPackage;
    // public WhiteCenterTextures: FaceTexturesPackage;

    public MatBlackFace: THREE.MeshPhongMaterial;

    private cubeMap: Map<CubeType, THREE.Group>;
    public CenterBall: THREE.Mesh;

    constructor() {
        super();
        this.FaceTextures = new FaceTexturesPackage();
        // this.WhiteCenterTextures = new FaceTexturesPackage();
        this.cubeMap = new Map<CubeType, THREE.Group>();
        for (let y = CubeType.D; y >= 0; y -= 100) {
            for (let x = CubeType.R; x >= 0; x -= 10) {
                for (let z = CubeType.B; z >= 0; z -= 1) {
                    const cubeType = y + x + z;
                    const group = new THREE.Group();
                    group.position.set(0, 0, 0);
                    this.cubeMap.set(cubeType, group);
                }
            }
        }
    }
    public GetBeamGeo(): THREE.BufferGeometry | THREE.Geometry {
        return this.BeamGeo;
    }

    public GenerateCube(position: THREE.Vector3, step: number): AnyCubeController {
        const lowLimit = -step / 2.0 + 0.5;
        const hightLimit = step / 2 - 0.5;
        const X = this.selectCubeType(position.x, lowLimit, hightLimit, CubeType.R, CubeType.L);
        const Y = this.selectCubeType(position.y, lowLimit, hightLimit, CubeType.D, CubeType.U);
        const Z = this.selectCubeType(position.z, lowLimit, hightLimit, CubeType.B, CubeType.F);
        const cubeType = X + Y + Z;
        const cube = this.cubeMap.get(cubeType);
        const faces = [undefined, undefined, undefined, undefined, undefined, undefined];
        let inFace: THREE.Object3D;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < cube.children.length; i++) {
            switch (cube.children[i].name) {
                case 'D':
                    faces[0] = cube.children[i].clone();
                    faces[0].material = this.MatBlackFace.clone();
                    break;
                case 'U':
                    faces[1] = cube.children[i].clone();
                    faces[1].material = this.MatBlackFace.clone();
                    break;
                case 'R':
                    faces[2] = cube.children[i].clone();
                    faces[2].material = this.MatBlackFace.clone();
                    break;
                case 'L':
                    faces[3] = cube.children[i].clone();
                    faces[3].material = this.MatBlackFace.clone();
                    break;
                case 'B':
                    faces[4] = cube.children[i].clone();
                    faces[4].material = this.MatBlackFace.clone();
                    break;
                case 'F':
                    faces[5] = cube.children[i].clone();
                    faces[5].material = this.MatBlackFace.clone();
                    break;
                case '0':
                    inFace = cube.children[i].clone();
                    (inFace as THREE.Mesh).material = this.MatBlackFace.clone();
                    break;
            }
        }
        for (let i = 0; i < 6; i++) {
            if (faces[i] === undefined) {
                faces[i] = inFace;
            }
            if (faces[i] !== undefined) {
                (faces[i] as THREE.Mesh).receiveShadow = true;
            }
        }
        const res = new AnyCubeController(position);
        let faceTextures = [];
        faceTextures = [this.FaceTextures, this.FaceTextures,
        this.FaceTextures, this.FaceTextures,
        this.FaceTextures, this.FaceTextures];
        res.AddFaces(faces, faceTextures);
        res.UpdateColor(Tools.GenerateInitColorFromPosition(position, step));
        return res;
    }

    public AddStaticMeshToCube(cube: THREE.Group): void {
        cube.add(this.CenterBall.clone());
    }

    // tslint:disable-next-line:variable-name
    private selectCubeType(value: number, l: number, h: number, sel_l: CubeType, sel_h): CubeType {
        if (value === l) { return sel_l; } else if (value === h) { return sel_h; } else { return 0; }
    }

    public GenerateFillColorCube(position: THREE.Vector3, step: number): FillColorCubeController {
        const lowLimit = -step / 2.0 + 0.5;
        const hightLimit = step / 2 - 0.5;
        const X = this.selectCubeType(position.x, lowLimit, hightLimit, CubeType.R, CubeType.L);
        const Y = this.selectCubeType(position.y, lowLimit, hightLimit, CubeType.D, CubeType.U);
        const Z = this.selectCubeType(position.z, lowLimit, hightLimit, CubeType.B, CubeType.F);
        const cubeType = X + Y + Z;
        const cube = this.cubeMap.get(cubeType);
        const faces = [undefined, undefined, undefined, undefined, undefined, undefined];
        let inFace: THREE.Object3D;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < cube.children.length; i++) {
            switch (cube.children[i].name) {
                case 'D':
                    faces[0] = cube.children[i].clone();
                    faces[0].material = this.MatBlackFace.clone();
                    break;
                case 'U':
                    faces[1] = cube.children[i].clone();
                    faces[1].material = this.MatBlackFace.clone();
                    break;
                case 'R':
                    faces[2] = cube.children[i].clone();
                    faces[2].material = this.MatBlackFace.clone();
                    break;
                case 'L':
                    faces[3] = cube.children[i].clone();
                    faces[3].material = this.MatBlackFace.clone();
                    break;
                case 'B':
                    faces[4] = cube.children[i].clone();
                    faces[4].material = this.MatBlackFace.clone();
                    break;
                case 'F':
                    faces[5] = cube.children[i].clone();
                    faces[5].material = this.MatBlackFace.clone();
                    break;
                case '0':
                    inFace = cube.children[i].clone();
                    (inFace as THREE.Mesh).material = this.MatBlackFace.clone();
                    break;
            }
        }
        for (let i = 0; i < 6; i++) {
            if (faces[i] === undefined) {
                faces[i] = inFace;
            }
            if (faces[i] !== undefined) {
                (faces[i] as THREE.Mesh).receiveShadow = true;
            }
        }

        let faceTextures = [];
        faceTextures = [this.FaceTextures, this.FaceTextures,
        this.FaceTextures, this.FaceTextures,
        this.FaceTextures, this.FaceTextures];

        const cubeController = new FillColorCubeController(position);
        cubeController.AddFaces(faces, faceTextures);
        return cubeController;
    }

    public async generateFaceMeshes(): Promise<void> {
        Debug.Log('Generate face meshes done.');
    }

    public async generateMaterials(): Promise<void> {
        this.MatBlackFace = new THREE.MeshPhongMaterial({
            map: this.FaceTextures.GetBlack(),
            side: THREE.DoubleSide,
        });
        this.disposeOperations.push(() => {
            this.MatBlackFace.dispose();
        });
    }

    public async loadAllModelsAndTextures(): Promise<void> {
        await Promise.all([
            this.loadAllModels(),
            this.loadAllTextures(),
        ]);
    }

    private async loadAllTextures() {
        [this.FaceTextures.TexYellow,
        this.FaceTextures.TexWhite,
        this.FaceTextures.TexRed,
        this.FaceTextures.TexOrange,
        this.FaceTextures.TexBlue,
        this.FaceTextures.TexGreen,
        this.FaceTextures.TexBlack] = await Promise.all([
            loadTexture('assets/MagicCube/CubeModels/GDCube/tp_yellow.png'),
            loadTexture('assets/MagicCube/CubeModels/GDCube/tp_white.png'),
            loadTexture('assets/MagicCube/CubeModels/GDCube/tp_red.png'),
            loadTexture('assets/MagicCube/CubeModels/GDCube/tp_orange.png'),
            loadTexture('assets/MagicCube/CubeModels/GDCube/tp_blue.png'),
            loadTexture('assets/MagicCube/CubeModels/GDCube/tp_green.png'),
            loadTexture('assets/MagicCube/CubeModels/GDCube/tp_black.png'),
        ]);
        this.disposeOperations.push(() => {
            this.FaceTextures.dispose();
        });
    }

    private async loadAllModels() {
        [this.BeamGeo,
            this.OriginWholeCube,
            this.AxisArrowY0,
            this.AxisArrowY1,
            this.AxisArrowZ0,
            this.AxisArrowZ1,
            this.AxisArrowX0,
            this.AxisArrowX1,
            ] = await Promise.all([
                this.loadBeamModel(),
                this.loadBaseCube(),
                this.loadAxisArrowY0(),
                this.loadAxisArrowY1(),
                this.loadAxisArrowZ0(),
                this.loadAxisArrowZ1(),
                this.loadAxisArrowX0(),
                this.loadAxisArrowX1(),
            ]);
        this.disposeOperations.push(() => {
            this.BeamGeo.dispose();
            this.AxisArrowX0.dispose();
            this.AxisArrowX1.dispose();
            this.AxisArrowY0.dispose();
            this.AxisArrowY1.dispose();
            this.AxisArrowZ0.dispose();
            this.AxisArrowZ1.dispose();
        });
    }

    private loadBaseCube(): Promise<THREE.Group> {
        return new Promise<THREE.Group>((resolve, reject) => {
            fbxLoader.load('assets/MagicCube/CubeModels/GDCube/Cube.FBX', (object: THREE.Group) => {

                const childrenCount = object.children.length;
                const children = new Array<THREE.Object3D>();
                for (let i = 0; i < childrenCount; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        children.push(object.children[i].clone());
                    }
                }

                children.forEach((value: THREE.Object3D, index: number) => {
                    (value as THREE.Mesh).geometry.scale(1 / 18, 1 / 18, 1 / 18);
                    if (value.name !== '0') {
                        const str = value.name.split('_', 2);
                        value.position.set(0, 0, 0);
                        value.name = str[1];
                        this.addFaceByName(str[0], value as THREE.Mesh);
                    } else {
                        this.CenterBall = (value as THREE.Mesh).clone();
                    }
                });

                Debug.Log('Load Base Cube', this.cubeMap);
                resolve(object);
            });
        });
    }

    private addFaceByName(typeStr: string, face: THREE.Mesh) {
        let cubeType: CubeType;
        switch (typeStr) {
            case 'DLB':
                cubeType = CubeType.DLB;
                break;
            case 'DRB':
                cubeType = CubeType.DRB;
                break;
            case 'DRF':
                cubeType = CubeType.DRF;
                break;
            case 'DLF':
                cubeType = CubeType.DLF;
                break;
            case 'ULB':
                cubeType = CubeType.ULB;
                break;
            case 'URB':
                cubeType = CubeType.URB;
                break;
            case 'URF':
                cubeType = CubeType.URF;
                break;
            case 'ULF':
                cubeType = CubeType.ULF;
                break;
            case 'DB':
                cubeType = CubeType.DB;
                break;
            case 'DR':
                cubeType = CubeType.DR;
                break;
            case 'DF':
                cubeType = CubeType.DF;
                break;
            case 'DL':
                cubeType = CubeType.DL;
                break;
            case 'UB':
                cubeType = CubeType.UB;
                break;
            case 'UR':
                cubeType = CubeType.UR;
                break;
            case 'UF':
                cubeType = CubeType.UF;
                break;
            case 'UL':
                cubeType = CubeType.UL;
                break;
            case 'LB':
                cubeType = CubeType.LB;
                break;
            case 'RB':
                cubeType = CubeType.RB;
                break;
            case 'RF':
                cubeType = CubeType.RF;
                break;
            case 'LF':
                cubeType = CubeType.LF;
                break;
            case 'D':
                cubeType = CubeType.D;
                break;
            case 'U':
                cubeType = CubeType.U;
                break;
            case 'L':
                cubeType = CubeType.L;
                break;
            case 'R':
                cubeType = CubeType.R;
                break;
            case 'F':
                cubeType = CubeType.F;
                break;
            case 'B':
                cubeType = CubeType.B;
                break;
        }
        if (!this.cubeMap.has(cubeType)) {
            Debug.Log('Cube Type Error', cubeType, typeStr);
        }
        this.cubeMap.get(cubeType).add(face);
    }

    private loadBeamModel(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            fbxLoader.load('assets/MagicCube/Effects/ETFX_AuraVertical.FBX', (object: THREE.Group) => {
                const geo = (object.children[0] as THREE.Mesh).geometry;
                geo.scale(1, 1, 18);
                Debug.Log('Load Beam Model');
                resolve(geo);
            });
        });
    }
    
    private loadAxisArrowY0(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Y_0.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Y0', object);
                resolve(axisArrow);
            });
        });
    }
    
    private loadAxisArrowY1(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Y_1.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Y1', object);
                resolve(axisArrow);
            });
        });
    }
    
    private loadAxisArrowZ0(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Z_0.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Z0', object);
                resolve(axisArrow);
            });
        });
    }
    
    private loadAxisArrowZ1(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Z_1.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Z1', object);
                resolve(axisArrow);
            });
        });
    }
    
    
    private loadAxisArrowX0(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_X_0.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow X0', object);
                resolve(axisArrow);
            });
        });
    }
    
    private loadAxisArrowX1(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_X_1.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow X1', object);
                resolve(axisArrow);
            });
        });
    }

    public GetAxisArrowY0Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowY0;
    }
    public GetAxisArrowY1Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowY1;
    }

    public GetAxisArrowZ0Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowZ0;
    }

    public GetAxisArrowZ1Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowZ1;
    }

    public GetAxisArrowX0Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowX0;
    }

    public GetAxisArrowX1Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowX1;
    }

}

export class DDAssets extends Assets {
    public AxisArrowY0: THREE.BufferGeometry | THREE.Geometry;
    public AxisArrowY1: THREE.BufferGeometry | THREE.Geometry;
    public AxisArrowZ0: THREE.BufferGeometry | THREE.Geometry;
    public AxisArrowZ1: THREE.BufferGeometry | THREE.Geometry;
    public AxisArrowX0: THREE.BufferGeometry | THREE.Geometry;
    public AxisArrowX1: THREE.BufferGeometry | THREE.Geometry;
    public BeamGeo: THREE.BufferGeometry | THREE.Geometry;
    public OriginWholeCube: THREE.Group;

    public FaceTextures: FaceTexturesPackage;

    public MatBlackFace: THREE.MeshPhongMaterial;

    private cubeMap: Map<CubeType, THREE.Group>;
    public CenterBall: THREE.Mesh;

    constructor() {
        super();
        this.FaceTextures = new FaceTexturesPackage();
        this.cubeMap = new Map<CubeType, THREE.Group>();
        for (let y = CubeType.D; y >= 0; y -= 100) {
            for (let x = CubeType.R; x >= 0; x -= 10) {
                for (let z = CubeType.B; z >= 0; z -= 1) {
                    const cubeType = y + x + z;
                    const group = new THREE.Group();
                    group.position.set(0, 0, 0);
                    this.cubeMap.set(cubeType, group);
                }
            }
        }
    }
    public GetBeamGeo(): THREE.BufferGeometry | THREE.Geometry {
        return this.BeamGeo;
    }

    public GenerateCube(position: THREE.Vector3, step: number): AnyCubeController {
        const lowLimit = -step / 2.0 + 0.5;
        const hightLimit = step / 2 - 0.5;
        const X = this.selectCubeType(position.x, lowLimit, hightLimit, CubeType.R, CubeType.L);
        const Y = this.selectCubeType(position.y, lowLimit, hightLimit, CubeType.D, CubeType.U);
        const Z = this.selectCubeType(position.z, lowLimit, hightLimit, CubeType.B, CubeType.F);
        const cubeType = X + Y + Z;
        const cube = this.cubeMap.get(cubeType);
        const faces = [undefined, undefined, undefined, undefined, undefined, undefined];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < cube.children.length; i++) {
            switch (cube.children[i].name) {
                case 'D':
                    faces[0] = cube.children[i].clone();
                    faces[0].material = this.MatBlackFace.clone();
                    break;
                case 'U':
                    faces[1] = cube.children[i].clone();
                    faces[1].material = this.MatBlackFace.clone();
                    break;
                case 'R':
                    faces[2] = cube.children[i].clone();
                    faces[2].material = this.MatBlackFace.clone();
                    break;
                case 'L':
                    faces[3] = cube.children[i].clone();
                    faces[3].material = this.MatBlackFace.clone();
                    break;
                case 'B':
                    faces[4] = cube.children[i].clone();
                    faces[4].material = this.MatBlackFace.clone();
                    break;
                case 'F':
                    faces[5] = cube.children[i].clone();
                    faces[5].material = this.MatBlackFace.clone();
                    break;
            }
        }
        for (let i = 0; i < 6; i++) {
            if (faces[i] !== undefined) {
                (faces[i] as THREE.Mesh).receiveShadow = true;
            }
        }
        const res = new AnyCubeController(position);
        let faceTextures = [];
        faceTextures = [this.FaceTextures, this.FaceTextures,
        this.FaceTextures, this.FaceTextures,
        this.FaceTextures, this.FaceTextures];
        res.AddFaces(faces, faceTextures);
        res.UpdateColor(Tools.GenerateInitColorFromPosition(position, step));
        return res;
    }

    public AddStaticMeshToCube(cube: THREE.Group): void {
        cube.add(this.CenterBall.clone());
    }

    // tslint:disable-next-line:variable-name
    private selectCubeType(value: number, l: number, h: number, sel_l: CubeType, sel_h): CubeType {
        if (value === l) { return sel_l; } else if (value === h) { return sel_h; } else { return 0; }
    }

    public GenerateFillColorCube(position: THREE.Vector3, step: number): FillColorCubeController {
        const lowLimit = -step / 2.0 + 0.5;
        const hightLimit = step / 2 - 0.5;
        const X = this.selectCubeType(position.x, lowLimit, hightLimit, CubeType.R, CubeType.L);
        const Y = this.selectCubeType(position.y, lowLimit, hightLimit, CubeType.D, CubeType.U);
        const Z = this.selectCubeType(position.z, lowLimit, hightLimit, CubeType.B, CubeType.F);
        const cubeType = X + Y + Z;
        const cube = this.cubeMap.get(cubeType);
        const faces = [undefined, undefined, undefined, undefined, undefined, undefined];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < cube.children.length; i++) {
            switch (cube.children[i].name) {
                case 'D':
                    faces[0] = cube.children[i].clone();
                    faces[0].material = this.MatBlackFace.clone();
                    break;
                case 'U':
                    faces[1] = cube.children[i].clone();
                    faces[1].material = this.MatBlackFace.clone();
                    break;
                case 'R':
                    faces[2] = cube.children[i].clone();
                    faces[2].material = this.MatBlackFace.clone();
                    break;
                case 'L':
                    faces[3] = cube.children[i].clone();
                    faces[3].material = this.MatBlackFace.clone();
                    break;
                case 'B':
                    faces[4] = cube.children[i].clone();
                    faces[4].material = this.MatBlackFace.clone();
                    break;
                case 'F':
                    faces[5] = cube.children[i].clone();
                    faces[5].material = this.MatBlackFace.clone();
                    break;
            }
        }
        for (let i = 0; i < 6; i++) {
            if (faces[i] !== undefined) {
                (faces[i] as THREE.Mesh).receiveShadow = true;
            }
        }

        let faceTextures = [];
        faceTextures = [this.FaceTextures, this.FaceTextures,
        this.FaceTextures, this.FaceTextures,
        this.FaceTextures, this.FaceTextures];

        const cubeController = new FillColorCubeController(position);
        cubeController.AddFaces(faces, faceTextures);
        return cubeController;
    }

    public async generateFaceMeshes(): Promise<void> {
        Debug.Log('Generate face meshes done.');
    }

    public async generateMaterials(): Promise<void> {
        this.MatBlackFace = new THREE.MeshPhongMaterial({
            map: this.FaceTextures.GetBlack(),
            side: THREE.DoubleSide,
        });
        this.disposeOperations.push(() => {
            this.MatBlackFace.dispose();
        });
    }

    public async loadAllModelsAndTextures(): Promise<void> {
        await Promise.all([
            this.loadAllModels(),
            this.loadAllTextures(),
        ]);
    }

    private async loadAllTextures() {
        [this.FaceTextures.TexYellow,
        this.FaceTextures.TexWhite,
        this.FaceTextures.TexRed,
        this.FaceTextures.TexOrange,
        this.FaceTextures.TexBlue,
        this.FaceTextures.TexGreen,
        this.FaceTextures.TexBlack] = await Promise.all([
            loadTexture('assets/MagicCube/CubeModels/DDCube/tp_yellow.png'),
            loadTexture('assets/MagicCube/CubeModels/DDCube/tp_white.png'),
            loadTexture('assets/MagicCube/CubeModels/DDCube/tp_red.png'),
            loadTexture('assets/MagicCube/CubeModels/DDCube/tp_orange.png'),
            loadTexture('assets/MagicCube/CubeModels/DDCube/tp_blue.png'),
            loadTexture('assets/MagicCube/CubeModels/DDCube/tp_green.png'),
            loadTexture('assets/MagicCube/CubeModels/DDCube/tp_black.png'),
        ]);
        this.disposeOperations.push(() => {
            this.FaceTextures.dispose();
        });
    }

    private async loadAllModels() {
        [this.BeamGeo,
        this.OriginWholeCube,
        this.AxisArrowY0,
        this.AxisArrowY1,
        this.AxisArrowZ0,
        this.AxisArrowZ1,
        this.AxisArrowX0,
        this.AxisArrowX1,
        ] = await Promise.all([
            this.loadBeamModel(),
            this.loadBaseCube(),
            this.loadAxisArrowY0(),
            this.loadAxisArrowY1(),
            this.loadAxisArrowZ0(),
            this.loadAxisArrowZ1(),
            this.loadAxisArrowX0(),
            this.loadAxisArrowX1(),
        ]);
        this.disposeOperations.push(() => {
            this.BeamGeo.dispose();
            this.AxisArrowY0.dispose();
            this.AxisArrowY1.dispose();
            this.AxisArrowZ0.dispose();
            this.AxisArrowZ1.dispose();
            this.AxisArrowX0.dispose();
            this.AxisArrowX1.dispose();
        });
    }

    private loadBaseCube(): Promise<THREE.Group> {
        return new Promise<THREE.Group>((resolve, reject) => {
            fbxLoader.load('assets/MagicCube/CubeModels/DDCube/Cube.FBX', (object: THREE.Group) => {

                const childrenCount = object.children.length;
                const children = new Array<THREE.Object3D>();
                for (let i = 0; i < childrenCount; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        children.push(object.children[i].clone());
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                    }
                }

                children.forEach((value: THREE.Object3D, index: number) => {
                    (value as THREE.Mesh).geometry.scale(1 / 18, 1 / 18, 1 / 18);
                    if (value.name !== '0') {
                        const str = value.name.split('_', 2);
                        value.position.set(0, 0, 0);
                        value.name = str[1];
                        this.addFaceByName(str[0], value as THREE.Mesh);
                    } else {
                        this.CenterBall = (value as THREE.Mesh).clone();
                        (value as THREE.Mesh).geometry.dispose();
                    }
                });

                Debug.Log('Load Base Cube', this.cubeMap);
                resolve(object);
            });
        });
    }

    private addFaceByName(typeStr: string, face: THREE.Mesh) {
        let cubeType: CubeType;
        switch (typeStr) {
            case 'DLB':
                cubeType = CubeType.DLB;
                break;
            case 'DRB':
                cubeType = CubeType.DRB;
                break;
            case 'DRF':
                cubeType = CubeType.DRF;
                break;
            case 'DLF':
                cubeType = CubeType.DLF;
                break;
            case 'ULB':
                cubeType = CubeType.ULB;
                break;
            case 'URB':
                cubeType = CubeType.URB;
                break;
            case 'URF':
                cubeType = CubeType.URF;
                break;
            case 'ULF':
                cubeType = CubeType.ULF;
                break;
            case 'DB':
                cubeType = CubeType.DB;
                break;
            case 'DR':
                cubeType = CubeType.DR;
                break;
            case 'DF':
                cubeType = CubeType.DF;
                break;
            case 'DL':
                cubeType = CubeType.DL;
                break;
            case 'UB':
                cubeType = CubeType.UB;
                break;
            case 'UR':
                cubeType = CubeType.UR;
                break;
            case 'UF':
                cubeType = CubeType.UF;
                break;
            case 'UL':
                cubeType = CubeType.UL;
                break;
            case 'LB':
                cubeType = CubeType.LB;
                break;
            case 'RB':
                cubeType = CubeType.RB;
                break;
            case 'RF':
                cubeType = CubeType.RF;
                break;
            case 'LF':
                cubeType = CubeType.LF;
                break;
            case 'D':
                cubeType = CubeType.D;
                break;
            case 'U':
                cubeType = CubeType.U;
                break;
            case 'L':
                cubeType = CubeType.L;
                break;
            case 'R':
                cubeType = CubeType.R;
                break;
            case 'F':
                cubeType = CubeType.F;
                break;
            case 'B':
                cubeType = CubeType.B;
                break;
        }
        if (!this.cubeMap.has(cubeType)) {
            Debug.Log('Cube Type Error', cubeType, typeStr);
        }
        this.cubeMap.get(cubeType).add(face);
    }

    private loadBeamModel(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            fbxLoader.load('assets/MagicCube/Effects/ETFX_AuraVertical.FBX', (object: THREE.Group) => {
                const geo = (object.children[0] as THREE.Mesh).geometry;
                geo.scale(1, 1, 18);
                Debug.Log('Load Beam Model');
                resolve(geo);
            });
        });
    }

    private loadAxisArrowY0(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Y_0.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Y0', object);
                resolve(axisArrow);
            });
        });
    }

    private loadAxisArrowY1(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Y_1.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Y1', object);
                resolve(axisArrow);
            });
        });
    }

    private loadAxisArrowZ0(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Z_0.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Z0', object);
                resolve(axisArrow);
            });
        });
    }

    private loadAxisArrowZ1(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Z_1.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Z1', object);
                resolve(axisArrow);
            });
        });
    }


    private loadAxisArrowX0(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_X_0.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow X0', object);
                resolve(axisArrow);
            });
        });
    }

    private loadAxisArrowX1(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_X_1.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow X1', object);
                resolve(axisArrow);
            });
        });
    }

    public GetAxisArrowY0Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowY0;
    }
    public GetAxisArrowY1Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowY1;
    }

    public GetAxisArrowZ0Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowZ0;
    }

    public GetAxisArrowZ1Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowZ1;
    }

    public GetAxisArrowX0Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowX0;
    }

    public GetAxisArrowX1Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowX1;
    }

}

export class GDTZAssets extends Assets {
    public AxisArrowY0: THREE.BufferGeometry | THREE.Geometry;
    public AxisArrowY1: THREE.BufferGeometry | THREE.Geometry;
    public AxisArrowZ0: THREE.BufferGeometry | THREE.Geometry;
    public AxisArrowZ1: THREE.BufferGeometry | THREE.Geometry;
    public AxisArrowX0: THREE.BufferGeometry | THREE.Geometry;
    public AxisArrowX1: THREE.BufferGeometry | THREE.Geometry;
    public BeamGeo: THREE.BufferGeometry | THREE.Geometry;
    public OriginWholeCube: THREE.Group;

    public FaceTextures: FaceTexturesPackage;

    public MatBlackFace: THREE.MeshStandardMaterial;
    public MatColorFace: THREE.MeshPhongMaterial;

    private cubeMap: Map<CubeType, THREE.Group>;
    public CenterBall: THREE.Mesh;

    constructor() {
        super();
        this.FaceTextures = new FaceTexturesPackage();
        this.cubeMap = new Map<CubeType, THREE.Group>();
        for (let y = CubeType.D; y >= 0; y -= 100) {
            for (let x = CubeType.R; x >= 0; x -= 10) {
                for (let z = CubeType.B; z >= 0; z -= 1) {
                    const cubeType = y + x + z;
                    const group = new THREE.Group();
                    group.position.set(0, 0, 0);
                    this.cubeMap.set(cubeType, group);
                }
            }
        }
    }
    public async generateFaceMeshes(): Promise<void> {
        Debug.Log('Generate face meshes done.');
    }
    public GetBeamGeo(): THREE.BufferGeometry | THREE.Geometry {
        return this.BeamGeo;
    }

    public GenerateCube(position: THREE.Vector3, step: number): AnyCubeController {
        const lowLimit = -step / 2.0 + 0.5;
        const hightLimit = step / 2 - 0.5;
        const X = this.selectCubeType(position.x, lowLimit, hightLimit, CubeType.R, CubeType.L);
        const Y = this.selectCubeType(position.y, lowLimit, hightLimit, CubeType.D, CubeType.U);
        const Z = this.selectCubeType(position.z, lowLimit, hightLimit, CubeType.B, CubeType.F);
        const cubeType = X + Y + Z;
        const cube = this.cubeMap.get(cubeType);
        const faces = [undefined, undefined, undefined, undefined, undefined, undefined];
        let inFace: THREE.Object3D;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < cube.children.length; i++) {
            switch (cube.children[i].name) {
                case 'D':
                    faces[0] = cube.children[i].clone();
                    faces[0].material = this.MatColorFace.clone();
                    break;
                case 'U':
                    faces[1] = cube.children[i].clone();
                    faces[1].material = this.MatColorFace.clone();
                    break;
                case 'R':
                    faces[2] = cube.children[i].clone();
                    faces[2].material = this.MatColorFace.clone();
                    break;
                case 'L':
                    faces[3] = cube.children[i].clone();
                    faces[3].material = this.MatColorFace.clone();
                    break;
                case 'B':
                    faces[4] = cube.children[i].clone();
                    faces[4].material = this.MatColorFace.clone();
                    break;
                case 'F':
                    faces[5] = cube.children[i].clone();
                    faces[5].material = this.MatColorFace.clone();
                    break;
                case '0':
                    inFace = cube.children[i].clone();
                    (inFace as THREE.Mesh).material = this.MatBlackFace.clone();
                    break;
            }
        }
        for (let i = 0; i < 6; i++) {
            if (faces[i] === undefined) {
                faces[i] = inFace;
            }
            if (faces[i] !== undefined) {
                (faces[i] as THREE.Mesh).receiveShadow = true;
            }
        }
        const res = new AnyCubeController(position);
        let faceTextures = [];
        faceTextures = [this.FaceTextures, this.FaceTextures,
        this.FaceTextures, this.FaceTextures,
        this.FaceTextures, this.FaceTextures];

        res.AddFaces(faces, faceTextures);
        res.UpdateColor(Tools.GenerateInitColorFromPosition(position, step));
        return res;
    }

    // tslint:disable-next-line:variable-name
    private selectCubeType(value: number, l: number, h: number, sel_l: CubeType, sel_h): CubeType {
        if (value === l) { return sel_l; } else if (value === h) { return sel_h; } else { return 0; }
    }

    public GenerateFillColorCube(position: THREE.Vector3, step: number): FillColorCubeController {
        const lowLimit = -step / 2.0 + 0.5;
        const hightLimit = step / 2 - 0.5;
        const X = this.selectCubeType(position.x, lowLimit, hightLimit, CubeType.R, CubeType.L);
        const Y = this.selectCubeType(position.y, lowLimit, hightLimit, CubeType.D, CubeType.U);
        const Z = this.selectCubeType(position.z, lowLimit, hightLimit, CubeType.B, CubeType.F);
        const cubeType = X + Y + Z;
        const cube = this.cubeMap.get(cubeType);
        const faces = [undefined, undefined, undefined, undefined, undefined, undefined];
        let inFace: THREE.Object3D;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < cube.children.length; i++) {
            switch (cube.children[i].name) {
                case 'D':
                    faces[0] = cube.children[i].clone();
                    faces[0].material = this.MatBlackFace.clone();
                    break;
                case 'U':
                    faces[1] = cube.children[i].clone();
                    faces[1].material = this.MatBlackFace.clone();
                    break;
                case 'R':
                    faces[2] = cube.children[i].clone();
                    faces[2].material = this.MatBlackFace.clone();
                    break;
                case 'L':
                    faces[3] = cube.children[i].clone();
                    faces[3].material = this.MatBlackFace.clone();
                    break;
                case 'B':
                    faces[4] = cube.children[i].clone();
                    faces[4].material = this.MatBlackFace.clone();
                    break;
                case 'F':
                    faces[5] = cube.children[i].clone();
                    faces[5].material = this.MatBlackFace.clone();
                    break;
                case '0':
                    inFace = cube.children[i].clone();
                    (inFace as THREE.Mesh).material = this.MatBlackFace.clone();
                    break;
            }
        }
        for (let i = 0; i < 6; i++) {
            if (faces[i] === undefined) {
                faces[i] = inFace;
            }
            if (faces[i] !== undefined) {
                (faces[i] as THREE.Mesh).receiveShadow = true;
            }
        }

        let faceTextures = [];
        faceTextures = [this.FaceTextures, this.FaceTextures,
        this.FaceTextures, this.FaceTextures,
        this.FaceTextures, this.FaceTextures];

        const cubeController = new FillColorCubeController(position);
        cubeController.AddFaces(faces, faceTextures);
        return cubeController;
    }
    public AddStaticMeshToCube(cube: THREE.Group): void {
        cube.add(this.CenterBall.clone());
    }

    public async generateMaterials(): Promise<void> {
        this.MatBlackFace = new THREE.MeshStandardMaterial({
            map: this.FaceTextures.GetBlack(),
            side: THREE.DoubleSide,
            roughness: 0.5,
            color: 0xffffff,
        });

        this.MatColorFace = new THREE.MeshPhongMaterial({
            map: this.FaceTextures.GetBlack(),
            side: THREE.DoubleSide,
            shininess: 0,
        });
        this.disposeOperations.push(() => {
            this.MatBlackFace.dispose();
        });
    }

    public async loadAllModelsAndTextures(): Promise<void> {
        await Promise.all([
            this.loadAllModels(),
            this.loadAllTextures(),
        ]);
    }

    private async loadAllTextures() {
        [this.FaceTextures.TexYellow,
        this.FaceTextures.TexWhite,
        this.FaceTextures.TexRed,
        this.FaceTextures.TexOrange,
        this.FaceTextures.TexBlue,
        this.FaceTextures.TexGreen,
        this.FaceTextures.TexBlack] = await Promise.all([
            loadTexture('assets/MagicCube/CubeModels/GDTZCube/tz_yellow.png'),
            loadTexture('assets/MagicCube/CubeModels/GDTZCube/tz_white.png'),
            loadTexture('assets/MagicCube/CubeModels/GDTZCube/tz_red.png'),
            loadTexture('assets/MagicCube/CubeModels/GDTZCube/tz_orange.png'),
            loadTexture('assets/MagicCube/CubeModels/GDTZCube/tz_blue.png'),
            loadTexture('assets/MagicCube/CubeModels/GDTZCube/tz_green.png'),
            loadTexture('assets/MagicCube/CubeModels/GDTZCube/tz_black.png'),
        ]);
        this.disposeOperations.push(() => {
            this.FaceTextures.dispose();
        });
    }

    private async loadAllModels() {
        [this.BeamGeo,
            this.OriginWholeCube,
            this.AxisArrowY0,
            this.AxisArrowY1,
            this.AxisArrowZ0,
            this.AxisArrowZ1,
            this.AxisArrowX0,
            this.AxisArrowX1,
            ] = await Promise.all([
                this.loadBeamModel(),
                this.loadBaseCube(),
                this.loadAxisArrowY0(),
                this.loadAxisArrowY1(),
                this.loadAxisArrowZ0(),
                this.loadAxisArrowZ1(),
                this.loadAxisArrowX0(),
                this.loadAxisArrowX1(),
            ]);
        this.disposeOperations.push(() => {
            this.BeamGeo.dispose();
            this.AxisArrowY0.dispose();
            this.AxisArrowY1.dispose();
            this.AxisArrowZ0.dispose();
            this.AxisArrowZ1.dispose();
            this.AxisArrowX0.dispose();
            this.AxisArrowX1.dispose();
        });
    }

    private loadBaseCube(): Promise<THREE.Group> {
        return new Promise<THREE.Group>((resolve, reject) => {
            fbxLoader.load('assets/MagicCube/CubeModels/GDTZCube/GDTZCube.FBX', (object: THREE.Group) => {

                const childrenCount = object.children.length;
                const children = new Array<THREE.Object3D>();
                for (let i = 0; i < childrenCount; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        children.push(object.children[i].clone());
                    }
                }

                children.forEach((value: THREE.Object3D, index: number) => {
                    (value as THREE.Mesh).geometry.scale(1 / 18, 1 / 18, 1 / 18);
                    if (value.name !== '0') {
                        const str = value.name.split('_', 2);
                        value.position.set(0, 0, 0);
                        value.name = str[1];
                        this.addFaceByName(str[0], value as THREE.Mesh);
                        switch (value.name) {
                            case 'R':
                                (value as THREE.Mesh).geometry.translate(-0.01, 0, 0);
                                break;
                            case 'L':
                                (value as THREE.Mesh).geometry.translate(0.01, 0, 0);
                                break;
                            case 'D':
                                (value as THREE.Mesh).geometry.translate(0, -0.01, 0);
                                break;
                            case 'U':
                                (value as THREE.Mesh).geometry.translate(0, 0.01, 0);
                                break;
                            case 'F':
                                (value as THREE.Mesh).geometry.translate(0, 0, 0.01);
                                break;
                            case 'B':
                                (value as THREE.Mesh).geometry.translate(0, 0, -0.01);
                                break;
                        }
                    } else {
                        this.CenterBall = (value as THREE.Mesh).clone();
                    }
                });

                Debug.Log('Load Base Cube', this.cubeMap);
                resolve(object);
            });
        });
    }

    private addFaceByName(typeStr: string, face: THREE.Mesh) {
        let cubeType: CubeType;
        switch (typeStr) {
            case 'DLB':
                cubeType = CubeType.DLB;
                break;
            case 'DRB':
                cubeType = CubeType.DRB;
                break;
            case 'DRF':
                cubeType = CubeType.DRF;
                break;
            case 'DLF':
                cubeType = CubeType.DLF;
                break;
            case 'ULB':
                cubeType = CubeType.ULB;
                break;
            case 'URB':
                cubeType = CubeType.URB;
                break;
            case 'URF':
                cubeType = CubeType.URF;
                break;
            case 'ULF':
                cubeType = CubeType.ULF;
                break;
            case 'DB':
                cubeType = CubeType.DB;
                break;
            case 'DR':
                cubeType = CubeType.DR;
                break;
            case 'DF':
                cubeType = CubeType.DF;
                break;
            case 'DL':
                cubeType = CubeType.DL;
                break;
            case 'UB':
                cubeType = CubeType.UB;
                break;
            case 'UR':
                cubeType = CubeType.UR;
                break;
            case 'UF':
                cubeType = CubeType.UF;
                break;
            case 'UL':
                cubeType = CubeType.UL;
                break;
            case 'LB':
                cubeType = CubeType.LB;
                break;
            case 'RB':
                cubeType = CubeType.RB;
                break;
            case 'RF':
                cubeType = CubeType.RF;
                break;
            case 'LF':
                cubeType = CubeType.LF;
                break;
            case 'D':
                cubeType = CubeType.D;
                break;
            case 'U':
                cubeType = CubeType.U;
                break;
            case 'L':
                cubeType = CubeType.L;
                break;
            case 'R':
                cubeType = CubeType.R;
                break;
            case 'F':
                cubeType = CubeType.F;
                break;
            case 'B':
                cubeType = CubeType.B;
                break;
        }
        if (!this.cubeMap.has(cubeType)) {
            Debug.Log('Cube Type Error', cubeType, typeStr);
        }
        this.cubeMap.get(cubeType).add(face);
    }

    private loadBeamModel(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            fbxLoader.load('assets/MagicCube/Effects/ETFX_AuraVertical.FBX', (object: THREE.Group) => {
                const geo = (object.children[0] as THREE.Mesh).geometry;
                geo.scale(1, 1, 18);
                Debug.Log('Load Beam Model');
                resolve(geo);
            });
        });
    }
    
    private loadAxisArrowY0(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Y_0.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Y0', object);
                resolve(axisArrow);
            });
        });
    }
    
    private loadAxisArrowY1(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Y_1.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Y1', object);
                resolve(axisArrow);
            });
        });
    }
    
    private loadAxisArrowZ0(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Z_0.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Z0', object);
                resolve(axisArrow);
            });
        });
    }
    
    private loadAxisArrowZ1(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Z_1.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Z1', object);
                resolve(axisArrow);
            });
        });
    }
    
    
    private loadAxisArrowX0(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_X_0.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow X0', object);
                resolve(axisArrow);
            });
        });
    }
    
    private loadAxisArrowX1(): Promise<THREE.BufferGeometry | THREE.Geometry> {
        return new Promise<THREE.BufferGeometry | THREE.Geometry>((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_X_1.FBX', (object: THREE.Object3D) => {
                let axisArrow: THREE.BufferGeometry | THREE.Geometry;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = (object.children[i] as THREE.Mesh).geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        (object.children[i] as THREE.Mesh).geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow X1', object);
                resolve(axisArrow);
            });
        });
    }

    public GetAxisArrowY0Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowY0;
    }
    public GetAxisArrowY1Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowY1;
    }

    public GetAxisArrowZ0Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowZ0;
    }

    public GetAxisArrowZ1Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowZ1;
    }

    public GetAxisArrowX0Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowX0;
    }

    public GetAxisArrowX1Geo(): THREE.BufferGeometry | THREE.Geometry {
        return this.AxisArrowX1;
    }
}

