import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BleStateService } from 'src/app/services/ble-state.service';
import { BleService } from 'src/app/services/ble.service';
import { scan } from 'rxjs/operators';
export class BleDialogComponent {
    constructor(dialogRef, bleService, bleStateService, bleDialogData) {
        this.dialogRef = dialogRef;
        this.bleService = bleService;
        this.bleStateService = bleStateService;
        this.namePrefix = bleDialogData.namePrefix;
    }
    ngOnInit() {
        this.startScan();
    }
    startScan() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { isEnabled, hasPermission, isLocationEnabled } = yield this.bleService.requestPermission();
            if (isEnabled && hasPermission && isLocationEnabled) {
                this.bleScan$ = (yield this.bleService.startScan('MHC')).pipe(scan((acc, curr) => [...acc, curr], new Array()));
            }
            else {
                console.log('蓝牙未启动');
            }
        });
    }
    connect(device) {
        this.dialogRef.close(device);
    }
    close() {
        this.dialogRef.close(null);
    }
}
