import * as tslib_1 from "tslib";
import { Subject } from 'rxjs';
import { Buffer } from 'buffer';
import { BleStateService } from './ble-state.service';
import { PTPV2 } from 'src/libs/ptp-v2';
import { Debug } from 'src/libs/debug';
import { bufferToHex, sleep } from 'src/libs/utils';
import { IBle } from './ble.service';
import { BleConfigService } from './ble-config.service';
import { getUpdatesFromZipFileBytes, DfuTransportAnyBle, DfuOperation } from 'src/libs/nrf-dfu';
import * as i0 from "@angular/core";
import * as i1 from "./ble-config.service";
import * as i2 from "./ble-state.service";
const bluetooth = navigator.bluetooth;
export class BleBrowserService {
    constructor(bleConfigService, bleStateService) {
        this.bleConfigService = bleConfigService;
        this.bleStateService = bleStateService;
        this.ptpV2 = null;
        this.otaService = null;
        this._command$ = new Subject();
        this._rotate$ = new Subject();
        this._attitude$ = new Subject();
        this._otaProgress$ = new Subject();
        this.onGattServerDisconnected = (event) => {
            this._command$.error(event);
            this.clearConnection();
        };
    }
    get otaProgress$() {
        return this._otaProgress$;
    }
    get command$() {
        return this._command$;
    }
    get rotate$() {
        return this._rotate$;
    }
    get attitude$() {
        return this._attitude$;
    }
    checkPermission() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return { isEnabled: true, hasPermission: true, isLocationEnabled: true };
        });
    }
    requestPermission() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.checkPermission();
        });
    }
    startScan(namePrefix) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.bleStateService.scanning = true;
            const device = yield bluetooth.requestDevice({
                filters: [{ namePrefix }],
                optionalServices: [this.bleConfigService.DATA_SERVICE_UUID, this.bleConfigService.OTA_SERVICE_UUID]
            });
            this.bleStateService.devices[device.id] = device;
            const subject = new Subject();
            setTimeout(() => {
                subject.next(device);
                subject.complete();
            }, 100);
            return subject;
        });
    }
    stopScan() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.bleStateService.scanning = false;
        });
    }
    connect(deviceId, enableDebug) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(enableDebug);
            // 如果已经连接了设备，则将其断开
            if (this.bleStateService.connectedDevice) {
                this.disconnect(this.bleStateService.connectedDevice.id);
            }
            this._command$ = new Subject();
            this._rotate$ = new Subject();
            this._attitude$ = new Subject();
            this.ptpV2 = new PTPV2();
            const server = yield this.bleStateService.devices[deviceId].gatt.connect();
            this.bleStateService.devices[deviceId].addEventListener('gattserverdisconnected', this.onGattServerDisconnected);
            const dataService = yield server.getPrimaryService(this.bleConfigService.DATA_SERVICE_UUID);
            this.otaService = yield server.getPrimaryService(this.bleConfigService.OTA_SERVICE_UUID);
            this.commandWriteCharacteristic = yield dataService.getCharacteristic(this.bleConfigService.COMMAND_WRITE_CHARACTERISTIC_UUID);
            const commandReadCharacteristic = yield dataService.getCharacteristic(this.bleConfigService.COMMAND_READ_CHARACTERISTIC_UUID);
            const rotateReadCharacteristic = yield dataService.getCharacteristic(this.bleConfigService.ROTATE_READ_CHARACTERISTIC_UUID);
            const attitudeReadCharacteristic = yield dataService.getCharacteristic(this.bleConfigService.ATTITUDE_READ_CHARACTERISTIC_UUID);
            commandReadCharacteristic.addEventListener('characteristicvaluechanged', (event) => {
                const buff = Buffer.from(event.target.value.buffer);
                this.ptpV2.input(buff);
                let recv;
                while (recv = this.ptpV2.receive()) {
                    this._command$.next(recv);
                    Debug.receive(bufferToHex(recv));
                }
            });
            rotateReadCharacteristic.addEventListener('characteristicvaluechanged', (event) => {
                const buff = Buffer.from(event.target.value.buffer);
                this._rotate$.next(buff);
                Debug.rotate(bufferToHex(buff));
            });
            attitudeReadCharacteristic.addEventListener('characteristicvaluechanged', (event) => {
                const buff = Buffer.from(event.target.value.buffer);
                this._attitude$.next(buff);
                Debug.attitude(bufferToHex(buff));
            });
            yield commandReadCharacteristic.startNotifications();
            yield rotateReadCharacteristic.startNotifications();
            yield attitudeReadCharacteristic.startNotifications();
            if (enableDebug) {
                const debugCharacteristic = yield dataService.getCharacteristic(this.bleConfigService.DEBUG_CHARACTERISTIC_UUID);
                debugCharacteristic.addEventListener('characteristicvaluechanged', (event) => {
                    const buff = Buffer.from(event.target.value.buffer);
                    Debug.debugInfo(buff.toString());
                });
                yield debugCharacteristic.startNotifications();
            }
            this.bleStateService.connectedDevice = this.bleStateService.devices[deviceId];
            return this._command$;
        });
    }
    /**
     * 返回是否已经连接设备
     */
    get connected() {
        return !!this.bleStateService.connectedDevice;
    }
    clearConnection() {
        if (this.bleStateService.connectedDevice) {
            this.bleStateService.connectedDevice.removeEventListener('gattserverdisconnected', this.onGattServerDisconnected);
        }
        this.ptpV2 = null;
        this.bleStateService.connectedDevice = null;
        this.commandWriteCharacteristic = null;
        this.otaService = null;
        if (this._command$) {
            this._command$.complete();
            this._command$ = null;
        }
        if (this._rotate$) {
            this._rotate$.complete();
            this._rotate$ = null;
        }
        if (this._attitude$) {
            this._attitude$.complete();
            this._attitude$ = null;
        }
    }
    disconnect(deviceId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.bleStateService.devices[deviceId].gatt.disconnect();
            this.clearConnection();
        });
    }
    send(buff) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.bleStateService.connected) {
                throw new Error('还未连接设备，请先连接设备再发送数据');
            }
            this.ptpV2.send(buff);
            let outputBuff;
            while (outputBuff = this.ptpV2.output()) {
                yield this.commandWriteCharacteristic.writeValue(outputBuff);
            }
        });
    }
    ota(otaFileBuffer, mtu, prn) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const receiveData$ = new Subject();
            let dfuControlCharacteristic;
            let dfuPacketCharacteristic;
            const prepareBle = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                dfuControlCharacteristic = yield this.otaService.getCharacteristic(this.bleConfigService.DFU_CONTROL_CHARACTERISTIC_UUID);
                dfuPacketCharacteristic = yield this.otaService.getCharacteristic(this.bleConfigService.DFU_PACKET_CHARACTERISTIC_UUID);
                dfuControlCharacteristic.addEventListener('characteristicvaluechanged', (event) => {
                    const buff = Buffer.from(event.target.value.buffer);
                    receiveData$.next(buff);
                });
                yield dfuControlCharacteristic.startNotifications();
            });
            const writeCommandImpl = (buff) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                yield dfuControlCharacteristic.writeValue(buff);
            });
            const writeDataImpl = (buff) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const startTime = Date.now();
                yield dfuPacketCharacteristic.writeValue(buff);
                const endTime = Date.now();
                const delay = endTime - startTime - 40;
                if (delay > 0) {
                    yield sleep(delay);
                }
            });
            const onProgress = (stage, sendBytes, totalBytes) => {
                this._otaProgress$.next({ stage, sendBytes, totalBytes });
            };
            const updates = yield getUpdatesFromZipFileBytes(otaFileBuffer);
            const bleTransport = new DfuTransportAnyBle(prepareBle, writeCommandImpl, writeDataImpl, receiveData$, mtu, prn, onProgress);
            const dfu = new DfuOperation(updates, bleTransport);
            yield dfu.start(true);
        });
    }
    requestMtu(mtuSize) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //throw new Error('webbluetooth 还未实现 requestMtu')
            return mtuSize;
        });
    }
}
BleBrowserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BleBrowserService_Factory() { return new BleBrowserService(i0.ɵɵinject(i1.BleConfigService), i0.ɵɵinject(i2.BleStateService)); }, token: BleBrowserService, providedIn: "root" });
