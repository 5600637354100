import * as tslib_1 from "tslib";
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { AnyCubeController } from './MagicCubeAny/AnyCubeController';
import { FillColorCubeController } from './MagicCubeAny/FillColorScene/FillColorCubeController';
import { Debug } from './Util/Debug';
import { Tools } from './Util/Tools';
const textureLoader = new THREE.TextureLoader();
export function loadTexture(url) {
    return tslib_1.__awaiter(this, void 0, void 0, function* () {
        // return textureLoader.load(url);
        return new Promise((resolve, reject) => {
            resolve(textureLoader.load(url));
        });
    });
}
export const fbxLoader = new FBXLoader();
export const objLoader = new OBJLoader();
export class FaceTexturesPackage {
    GetRed() {
        return this.TexRed;
    }
    GetYellow() {
        return this.TexYellow;
    }
    GetGreen() {
        return this.TexGreen;
    }
    GetBlue() {
        return this.TexBlue;
    }
    GetOrange() {
        return this.TexOrange;
    }
    GetWhite() {
        return this.TexWhite;
    }
    GetBlack() {
        return this.TexBlack;
    }
    dispose() {
        this.TexYellow.dispose();
        this.TexRed.dispose();
        this.TexWhite.dispose();
        this.TexOrange.dispose();
        this.TexGreen.dispose();
        this.TexBlue.dispose();
    }
}
export class Assets {
    constructor() {
        this.disposeOperations = Array();
    }
    static LoadGDTZAllAssets() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const assets = new GDTZAssets();
            yield assets.loadAllModelsAndTextures();
            yield assets.generateMaterials();
            yield assets.generateFaceMeshes();
            return assets;
        });
    }
    static LoadDDAllAssets() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const assets = new DDAssets();
            yield assets.loadAllModelsAndTextures();
            yield assets.generateMaterials();
            yield assets.generateFaceMeshes();
            return assets;
        });
    }
    static LoadGDAllAssets() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const assets = new GDAssets();
            yield assets.loadAllModelsAndTextures();
            yield assets.generateMaterials();
            yield assets.generateFaceMeshes();
            return assets;
        });
    }
    dispose() {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.disposeOperations.length; i++) {
            this.disposeOperations[i]();
        }
    }
}
var CubeType;
(function (CubeType) {
    CubeType[CubeType["U"] = 100] = "U";
    CubeType[CubeType["D"] = 200] = "D";
    CubeType[CubeType["L"] = 10] = "L";
    CubeType[CubeType["R"] = 20] = "R";
    CubeType[CubeType["F"] = 1] = "F";
    CubeType[CubeType["B"] = 2] = "B";
    CubeType[CubeType["UL"] = 110] = "UL";
    CubeType[CubeType["UR"] = 120] = "UR";
    CubeType[CubeType["UB"] = 102] = "UB";
    CubeType[CubeType["UF"] = 101] = "UF";
    CubeType[CubeType["DL"] = 210] = "DL";
    CubeType[CubeType["DR"] = 220] = "DR";
    CubeType[CubeType["DF"] = 201] = "DF";
    CubeType[CubeType["DB"] = 202] = "DB";
    CubeType[CubeType["LF"] = 11] = "LF";
    CubeType[CubeType["LB"] = 12] = "LB";
    CubeType[CubeType["RF"] = 21] = "RF";
    CubeType[CubeType["RB"] = 22] = "RB";
    CubeType[CubeType["ULF"] = 111] = "ULF";
    CubeType[CubeType["ULB"] = 112] = "ULB";
    CubeType[CubeType["URF"] = 121] = "URF";
    CubeType[CubeType["URB"] = 122] = "URB";
    CubeType[CubeType["DLF"] = 211] = "DLF";
    CubeType[CubeType["DLB"] = 212] = "DLB";
    CubeType[CubeType["DRF"] = 221] = "DRF";
    CubeType[CubeType["DRB"] = 222] = "DRB";
})(CubeType || (CubeType = {}));
export class GDAssets extends Assets {
    constructor() {
        super();
        this.FaceTextures = new FaceTexturesPackage();
        // this.WhiteCenterTextures = new FaceTexturesPackage();
        this.cubeMap = new Map();
        for (let y = CubeType.D; y >= 0; y -= 100) {
            for (let x = CubeType.R; x >= 0; x -= 10) {
                for (let z = CubeType.B; z >= 0; z -= 1) {
                    const cubeType = y + x + z;
                    const group = new THREE.Group();
                    group.position.set(0, 0, 0);
                    this.cubeMap.set(cubeType, group);
                }
            }
        }
    }
    GetBeamGeo() {
        return this.BeamGeo;
    }
    GenerateCube(position, step) {
        const lowLimit = -step / 2.0 + 0.5;
        const hightLimit = step / 2 - 0.5;
        const X = this.selectCubeType(position.x, lowLimit, hightLimit, CubeType.R, CubeType.L);
        const Y = this.selectCubeType(position.y, lowLimit, hightLimit, CubeType.D, CubeType.U);
        const Z = this.selectCubeType(position.z, lowLimit, hightLimit, CubeType.B, CubeType.F);
        const cubeType = X + Y + Z;
        const cube = this.cubeMap.get(cubeType);
        const faces = [undefined, undefined, undefined, undefined, undefined, undefined];
        let inFace;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < cube.children.length; i++) {
            switch (cube.children[i].name) {
                case 'D':
                    faces[0] = cube.children[i].clone();
                    faces[0].material = this.MatBlackFace.clone();
                    break;
                case 'U':
                    faces[1] = cube.children[i].clone();
                    faces[1].material = this.MatBlackFace.clone();
                    break;
                case 'R':
                    faces[2] = cube.children[i].clone();
                    faces[2].material = this.MatBlackFace.clone();
                    break;
                case 'L':
                    faces[3] = cube.children[i].clone();
                    faces[3].material = this.MatBlackFace.clone();
                    break;
                case 'B':
                    faces[4] = cube.children[i].clone();
                    faces[4].material = this.MatBlackFace.clone();
                    break;
                case 'F':
                    faces[5] = cube.children[i].clone();
                    faces[5].material = this.MatBlackFace.clone();
                    break;
                case '0':
                    inFace = cube.children[i].clone();
                    inFace.material = this.MatBlackFace.clone();
                    break;
            }
        }
        for (let i = 0; i < 6; i++) {
            if (faces[i] === undefined) {
                faces[i] = inFace;
            }
            if (faces[i] !== undefined) {
                faces[i].receiveShadow = true;
            }
        }
        const res = new AnyCubeController(position);
        let faceTextures = [];
        faceTextures = [this.FaceTextures, this.FaceTextures,
            this.FaceTextures, this.FaceTextures,
            this.FaceTextures, this.FaceTextures];
        res.AddFaces(faces, faceTextures);
        res.UpdateColor(Tools.GenerateInitColorFromPosition(position, step));
        return res;
    }
    AddStaticMeshToCube(cube) {
        cube.add(this.CenterBall.clone());
    }
    // tslint:disable-next-line:variable-name
    selectCubeType(value, l, h, sel_l, sel_h) {
        if (value === l) {
            return sel_l;
        }
        else if (value === h) {
            return sel_h;
        }
        else {
            return 0;
        }
    }
    GenerateFillColorCube(position, step) {
        const lowLimit = -step / 2.0 + 0.5;
        const hightLimit = step / 2 - 0.5;
        const X = this.selectCubeType(position.x, lowLimit, hightLimit, CubeType.R, CubeType.L);
        const Y = this.selectCubeType(position.y, lowLimit, hightLimit, CubeType.D, CubeType.U);
        const Z = this.selectCubeType(position.z, lowLimit, hightLimit, CubeType.B, CubeType.F);
        const cubeType = X + Y + Z;
        const cube = this.cubeMap.get(cubeType);
        const faces = [undefined, undefined, undefined, undefined, undefined, undefined];
        let inFace;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < cube.children.length; i++) {
            switch (cube.children[i].name) {
                case 'D':
                    faces[0] = cube.children[i].clone();
                    faces[0].material = this.MatBlackFace.clone();
                    break;
                case 'U':
                    faces[1] = cube.children[i].clone();
                    faces[1].material = this.MatBlackFace.clone();
                    break;
                case 'R':
                    faces[2] = cube.children[i].clone();
                    faces[2].material = this.MatBlackFace.clone();
                    break;
                case 'L':
                    faces[3] = cube.children[i].clone();
                    faces[3].material = this.MatBlackFace.clone();
                    break;
                case 'B':
                    faces[4] = cube.children[i].clone();
                    faces[4].material = this.MatBlackFace.clone();
                    break;
                case 'F':
                    faces[5] = cube.children[i].clone();
                    faces[5].material = this.MatBlackFace.clone();
                    break;
                case '0':
                    inFace = cube.children[i].clone();
                    inFace.material = this.MatBlackFace.clone();
                    break;
            }
        }
        for (let i = 0; i < 6; i++) {
            if (faces[i] === undefined) {
                faces[i] = inFace;
            }
            if (faces[i] !== undefined) {
                faces[i].receiveShadow = true;
            }
        }
        let faceTextures = [];
        faceTextures = [this.FaceTextures, this.FaceTextures,
            this.FaceTextures, this.FaceTextures,
            this.FaceTextures, this.FaceTextures];
        const cubeController = new FillColorCubeController(position);
        cubeController.AddFaces(faces, faceTextures);
        return cubeController;
    }
    generateFaceMeshes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            Debug.Log('Generate face meshes done.');
        });
    }
    generateMaterials() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.MatBlackFace = new THREE.MeshPhongMaterial({
                map: this.FaceTextures.GetBlack(),
                side: THREE.DoubleSide,
            });
            this.disposeOperations.push(() => {
                this.MatBlackFace.dispose();
            });
        });
    }
    loadAllModelsAndTextures() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield Promise.all([
                this.loadAllModels(),
                this.loadAllTextures(),
            ]);
        });
    }
    loadAllTextures() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            [this.FaceTextures.TexYellow,
                this.FaceTextures.TexWhite,
                this.FaceTextures.TexRed,
                this.FaceTextures.TexOrange,
                this.FaceTextures.TexBlue,
                this.FaceTextures.TexGreen,
                this.FaceTextures.TexBlack] = yield Promise.all([
                loadTexture('assets/MagicCube/CubeModels/GDCube/tp_yellow.png'),
                loadTexture('assets/MagicCube/CubeModels/GDCube/tp_white.png'),
                loadTexture('assets/MagicCube/CubeModels/GDCube/tp_red.png'),
                loadTexture('assets/MagicCube/CubeModels/GDCube/tp_orange.png'),
                loadTexture('assets/MagicCube/CubeModels/GDCube/tp_blue.png'),
                loadTexture('assets/MagicCube/CubeModels/GDCube/tp_green.png'),
                loadTexture('assets/MagicCube/CubeModels/GDCube/tp_black.png'),
            ]);
            this.disposeOperations.push(() => {
                this.FaceTextures.dispose();
            });
        });
    }
    loadAllModels() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            [this.BeamGeo,
                this.OriginWholeCube,
                this.AxisArrowY0,
                this.AxisArrowY1,
                this.AxisArrowZ0,
                this.AxisArrowZ1,
                this.AxisArrowX0,
                this.AxisArrowX1,
            ] = yield Promise.all([
                this.loadBeamModel(),
                this.loadBaseCube(),
                this.loadAxisArrowY0(),
                this.loadAxisArrowY1(),
                this.loadAxisArrowZ0(),
                this.loadAxisArrowZ1(),
                this.loadAxisArrowX0(),
                this.loadAxisArrowX1(),
            ]);
            this.disposeOperations.push(() => {
                this.BeamGeo.dispose();
                this.AxisArrowX0.dispose();
                this.AxisArrowX1.dispose();
                this.AxisArrowY0.dispose();
                this.AxisArrowY1.dispose();
                this.AxisArrowZ0.dispose();
                this.AxisArrowZ1.dispose();
            });
        });
    }
    loadBaseCube() {
        return new Promise((resolve, reject) => {
            fbxLoader.load('assets/MagicCube/CubeModels/GDCube/Cube.FBX', (object) => {
                const childrenCount = object.children.length;
                const children = new Array();
                for (let i = 0; i < childrenCount; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        children.push(object.children[i].clone());
                    }
                }
                children.forEach((value, index) => {
                    value.geometry.scale(1 / 18, 1 / 18, 1 / 18);
                    if (value.name !== '0') {
                        const str = value.name.split('_', 2);
                        value.position.set(0, 0, 0);
                        value.name = str[1];
                        this.addFaceByName(str[0], value);
                    }
                    else {
                        this.CenterBall = value.clone();
                    }
                });
                Debug.Log('Load Base Cube', this.cubeMap);
                resolve(object);
            });
        });
    }
    addFaceByName(typeStr, face) {
        let cubeType;
        switch (typeStr) {
            case 'DLB':
                cubeType = CubeType.DLB;
                break;
            case 'DRB':
                cubeType = CubeType.DRB;
                break;
            case 'DRF':
                cubeType = CubeType.DRF;
                break;
            case 'DLF':
                cubeType = CubeType.DLF;
                break;
            case 'ULB':
                cubeType = CubeType.ULB;
                break;
            case 'URB':
                cubeType = CubeType.URB;
                break;
            case 'URF':
                cubeType = CubeType.URF;
                break;
            case 'ULF':
                cubeType = CubeType.ULF;
                break;
            case 'DB':
                cubeType = CubeType.DB;
                break;
            case 'DR':
                cubeType = CubeType.DR;
                break;
            case 'DF':
                cubeType = CubeType.DF;
                break;
            case 'DL':
                cubeType = CubeType.DL;
                break;
            case 'UB':
                cubeType = CubeType.UB;
                break;
            case 'UR':
                cubeType = CubeType.UR;
                break;
            case 'UF':
                cubeType = CubeType.UF;
                break;
            case 'UL':
                cubeType = CubeType.UL;
                break;
            case 'LB':
                cubeType = CubeType.LB;
                break;
            case 'RB':
                cubeType = CubeType.RB;
                break;
            case 'RF':
                cubeType = CubeType.RF;
                break;
            case 'LF':
                cubeType = CubeType.LF;
                break;
            case 'D':
                cubeType = CubeType.D;
                break;
            case 'U':
                cubeType = CubeType.U;
                break;
            case 'L':
                cubeType = CubeType.L;
                break;
            case 'R':
                cubeType = CubeType.R;
                break;
            case 'F':
                cubeType = CubeType.F;
                break;
            case 'B':
                cubeType = CubeType.B;
                break;
        }
        if (!this.cubeMap.has(cubeType)) {
            Debug.Log('Cube Type Error', cubeType, typeStr);
        }
        this.cubeMap.get(cubeType).add(face);
    }
    loadBeamModel() {
        return new Promise((resolve, reject) => {
            fbxLoader.load('assets/MagicCube/Effects/ETFX_AuraVertical.FBX', (object) => {
                const geo = object.children[0].geometry;
                geo.scale(1, 1, 18);
                Debug.Log('Load Beam Model');
                resolve(geo);
            });
        });
    }
    loadAxisArrowY0() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Y_0.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Y0', object);
                resolve(axisArrow);
            });
        });
    }
    loadAxisArrowY1() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Y_1.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Y1', object);
                resolve(axisArrow);
            });
        });
    }
    loadAxisArrowZ0() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Z_0.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Z0', object);
                resolve(axisArrow);
            });
        });
    }
    loadAxisArrowZ1() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Z_1.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Z1', object);
                resolve(axisArrow);
            });
        });
    }
    loadAxisArrowX0() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_X_0.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow X0', object);
                resolve(axisArrow);
            });
        });
    }
    loadAxisArrowX1() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_X_1.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow X1', object);
                resolve(axisArrow);
            });
        });
    }
    GetAxisArrowY0Geo() {
        return this.AxisArrowY0;
    }
    GetAxisArrowY1Geo() {
        return this.AxisArrowY1;
    }
    GetAxisArrowZ0Geo() {
        return this.AxisArrowZ0;
    }
    GetAxisArrowZ1Geo() {
        return this.AxisArrowZ1;
    }
    GetAxisArrowX0Geo() {
        return this.AxisArrowX0;
    }
    GetAxisArrowX1Geo() {
        return this.AxisArrowX1;
    }
}
export class DDAssets extends Assets {
    constructor() {
        super();
        this.FaceTextures = new FaceTexturesPackage();
        this.cubeMap = new Map();
        for (let y = CubeType.D; y >= 0; y -= 100) {
            for (let x = CubeType.R; x >= 0; x -= 10) {
                for (let z = CubeType.B; z >= 0; z -= 1) {
                    const cubeType = y + x + z;
                    const group = new THREE.Group();
                    group.position.set(0, 0, 0);
                    this.cubeMap.set(cubeType, group);
                }
            }
        }
    }
    GetBeamGeo() {
        return this.BeamGeo;
    }
    GenerateCube(position, step) {
        const lowLimit = -step / 2.0 + 0.5;
        const hightLimit = step / 2 - 0.5;
        const X = this.selectCubeType(position.x, lowLimit, hightLimit, CubeType.R, CubeType.L);
        const Y = this.selectCubeType(position.y, lowLimit, hightLimit, CubeType.D, CubeType.U);
        const Z = this.selectCubeType(position.z, lowLimit, hightLimit, CubeType.B, CubeType.F);
        const cubeType = X + Y + Z;
        const cube = this.cubeMap.get(cubeType);
        const faces = [undefined, undefined, undefined, undefined, undefined, undefined];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < cube.children.length; i++) {
            switch (cube.children[i].name) {
                case 'D':
                    faces[0] = cube.children[i].clone();
                    faces[0].material = this.MatBlackFace.clone();
                    break;
                case 'U':
                    faces[1] = cube.children[i].clone();
                    faces[1].material = this.MatBlackFace.clone();
                    break;
                case 'R':
                    faces[2] = cube.children[i].clone();
                    faces[2].material = this.MatBlackFace.clone();
                    break;
                case 'L':
                    faces[3] = cube.children[i].clone();
                    faces[3].material = this.MatBlackFace.clone();
                    break;
                case 'B':
                    faces[4] = cube.children[i].clone();
                    faces[4].material = this.MatBlackFace.clone();
                    break;
                case 'F':
                    faces[5] = cube.children[i].clone();
                    faces[5].material = this.MatBlackFace.clone();
                    break;
            }
        }
        for (let i = 0; i < 6; i++) {
            if (faces[i] !== undefined) {
                faces[i].receiveShadow = true;
            }
        }
        const res = new AnyCubeController(position);
        let faceTextures = [];
        faceTextures = [this.FaceTextures, this.FaceTextures,
            this.FaceTextures, this.FaceTextures,
            this.FaceTextures, this.FaceTextures];
        res.AddFaces(faces, faceTextures);
        res.UpdateColor(Tools.GenerateInitColorFromPosition(position, step));
        return res;
    }
    AddStaticMeshToCube(cube) {
        cube.add(this.CenterBall.clone());
    }
    // tslint:disable-next-line:variable-name
    selectCubeType(value, l, h, sel_l, sel_h) {
        if (value === l) {
            return sel_l;
        }
        else if (value === h) {
            return sel_h;
        }
        else {
            return 0;
        }
    }
    GenerateFillColorCube(position, step) {
        const lowLimit = -step / 2.0 + 0.5;
        const hightLimit = step / 2 - 0.5;
        const X = this.selectCubeType(position.x, lowLimit, hightLimit, CubeType.R, CubeType.L);
        const Y = this.selectCubeType(position.y, lowLimit, hightLimit, CubeType.D, CubeType.U);
        const Z = this.selectCubeType(position.z, lowLimit, hightLimit, CubeType.B, CubeType.F);
        const cubeType = X + Y + Z;
        const cube = this.cubeMap.get(cubeType);
        const faces = [undefined, undefined, undefined, undefined, undefined, undefined];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < cube.children.length; i++) {
            switch (cube.children[i].name) {
                case 'D':
                    faces[0] = cube.children[i].clone();
                    faces[0].material = this.MatBlackFace.clone();
                    break;
                case 'U':
                    faces[1] = cube.children[i].clone();
                    faces[1].material = this.MatBlackFace.clone();
                    break;
                case 'R':
                    faces[2] = cube.children[i].clone();
                    faces[2].material = this.MatBlackFace.clone();
                    break;
                case 'L':
                    faces[3] = cube.children[i].clone();
                    faces[3].material = this.MatBlackFace.clone();
                    break;
                case 'B':
                    faces[4] = cube.children[i].clone();
                    faces[4].material = this.MatBlackFace.clone();
                    break;
                case 'F':
                    faces[5] = cube.children[i].clone();
                    faces[5].material = this.MatBlackFace.clone();
                    break;
            }
        }
        for (let i = 0; i < 6; i++) {
            if (faces[i] !== undefined) {
                faces[i].receiveShadow = true;
            }
        }
        let faceTextures = [];
        faceTextures = [this.FaceTextures, this.FaceTextures,
            this.FaceTextures, this.FaceTextures,
            this.FaceTextures, this.FaceTextures];
        const cubeController = new FillColorCubeController(position);
        cubeController.AddFaces(faces, faceTextures);
        return cubeController;
    }
    generateFaceMeshes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            Debug.Log('Generate face meshes done.');
        });
    }
    generateMaterials() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.MatBlackFace = new THREE.MeshPhongMaterial({
                map: this.FaceTextures.GetBlack(),
                side: THREE.DoubleSide,
            });
            this.disposeOperations.push(() => {
                this.MatBlackFace.dispose();
            });
        });
    }
    loadAllModelsAndTextures() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield Promise.all([
                this.loadAllModels(),
                this.loadAllTextures(),
            ]);
        });
    }
    loadAllTextures() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            [this.FaceTextures.TexYellow,
                this.FaceTextures.TexWhite,
                this.FaceTextures.TexRed,
                this.FaceTextures.TexOrange,
                this.FaceTextures.TexBlue,
                this.FaceTextures.TexGreen,
                this.FaceTextures.TexBlack] = yield Promise.all([
                loadTexture('assets/MagicCube/CubeModels/DDCube/tp_yellow.png'),
                loadTexture('assets/MagicCube/CubeModels/DDCube/tp_white.png'),
                loadTexture('assets/MagicCube/CubeModels/DDCube/tp_red.png'),
                loadTexture('assets/MagicCube/CubeModels/DDCube/tp_orange.png'),
                loadTexture('assets/MagicCube/CubeModels/DDCube/tp_blue.png'),
                loadTexture('assets/MagicCube/CubeModels/DDCube/tp_green.png'),
                loadTexture('assets/MagicCube/CubeModels/DDCube/tp_black.png'),
            ]);
            this.disposeOperations.push(() => {
                this.FaceTextures.dispose();
            });
        });
    }
    loadAllModels() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            [this.BeamGeo,
                this.OriginWholeCube,
                this.AxisArrowY0,
                this.AxisArrowY1,
                this.AxisArrowZ0,
                this.AxisArrowZ1,
                this.AxisArrowX0,
                this.AxisArrowX1,
            ] = yield Promise.all([
                this.loadBeamModel(),
                this.loadBaseCube(),
                this.loadAxisArrowY0(),
                this.loadAxisArrowY1(),
                this.loadAxisArrowZ0(),
                this.loadAxisArrowZ1(),
                this.loadAxisArrowX0(),
                this.loadAxisArrowX1(),
            ]);
            this.disposeOperations.push(() => {
                this.BeamGeo.dispose();
                this.AxisArrowY0.dispose();
                this.AxisArrowY1.dispose();
                this.AxisArrowZ0.dispose();
                this.AxisArrowZ1.dispose();
                this.AxisArrowX0.dispose();
                this.AxisArrowX1.dispose();
            });
        });
    }
    loadBaseCube() {
        return new Promise((resolve, reject) => {
            fbxLoader.load('assets/MagicCube/CubeModels/DDCube/Cube.FBX', (object) => {
                const childrenCount = object.children.length;
                const children = new Array();
                for (let i = 0; i < childrenCount; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        children.push(object.children[i].clone());
                        object.children[i].geometry.dispose();
                    }
                }
                children.forEach((value, index) => {
                    value.geometry.scale(1 / 18, 1 / 18, 1 / 18);
                    if (value.name !== '0') {
                        const str = value.name.split('_', 2);
                        value.position.set(0, 0, 0);
                        value.name = str[1];
                        this.addFaceByName(str[0], value);
                    }
                    else {
                        this.CenterBall = value.clone();
                        value.geometry.dispose();
                    }
                });
                Debug.Log('Load Base Cube', this.cubeMap);
                resolve(object);
            });
        });
    }
    addFaceByName(typeStr, face) {
        let cubeType;
        switch (typeStr) {
            case 'DLB':
                cubeType = CubeType.DLB;
                break;
            case 'DRB':
                cubeType = CubeType.DRB;
                break;
            case 'DRF':
                cubeType = CubeType.DRF;
                break;
            case 'DLF':
                cubeType = CubeType.DLF;
                break;
            case 'ULB':
                cubeType = CubeType.ULB;
                break;
            case 'URB':
                cubeType = CubeType.URB;
                break;
            case 'URF':
                cubeType = CubeType.URF;
                break;
            case 'ULF':
                cubeType = CubeType.ULF;
                break;
            case 'DB':
                cubeType = CubeType.DB;
                break;
            case 'DR':
                cubeType = CubeType.DR;
                break;
            case 'DF':
                cubeType = CubeType.DF;
                break;
            case 'DL':
                cubeType = CubeType.DL;
                break;
            case 'UB':
                cubeType = CubeType.UB;
                break;
            case 'UR':
                cubeType = CubeType.UR;
                break;
            case 'UF':
                cubeType = CubeType.UF;
                break;
            case 'UL':
                cubeType = CubeType.UL;
                break;
            case 'LB':
                cubeType = CubeType.LB;
                break;
            case 'RB':
                cubeType = CubeType.RB;
                break;
            case 'RF':
                cubeType = CubeType.RF;
                break;
            case 'LF':
                cubeType = CubeType.LF;
                break;
            case 'D':
                cubeType = CubeType.D;
                break;
            case 'U':
                cubeType = CubeType.U;
                break;
            case 'L':
                cubeType = CubeType.L;
                break;
            case 'R':
                cubeType = CubeType.R;
                break;
            case 'F':
                cubeType = CubeType.F;
                break;
            case 'B':
                cubeType = CubeType.B;
                break;
        }
        if (!this.cubeMap.has(cubeType)) {
            Debug.Log('Cube Type Error', cubeType, typeStr);
        }
        this.cubeMap.get(cubeType).add(face);
    }
    loadBeamModel() {
        return new Promise((resolve, reject) => {
            fbxLoader.load('assets/MagicCube/Effects/ETFX_AuraVertical.FBX', (object) => {
                const geo = object.children[0].geometry;
                geo.scale(1, 1, 18);
                Debug.Log('Load Beam Model');
                resolve(geo);
            });
        });
    }
    loadAxisArrowY0() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Y_0.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Y0', object);
                resolve(axisArrow);
            });
        });
    }
    loadAxisArrowY1() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Y_1.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Y1', object);
                resolve(axisArrow);
            });
        });
    }
    loadAxisArrowZ0() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Z_0.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Z0', object);
                resolve(axisArrow);
            });
        });
    }
    loadAxisArrowZ1() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Z_1.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Z1', object);
                resolve(axisArrow);
            });
        });
    }
    loadAxisArrowX0() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_X_0.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow X0', object);
                resolve(axisArrow);
            });
        });
    }
    loadAxisArrowX1() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_X_1.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow X1', object);
                resolve(axisArrow);
            });
        });
    }
    GetAxisArrowY0Geo() {
        return this.AxisArrowY0;
    }
    GetAxisArrowY1Geo() {
        return this.AxisArrowY1;
    }
    GetAxisArrowZ0Geo() {
        return this.AxisArrowZ0;
    }
    GetAxisArrowZ1Geo() {
        return this.AxisArrowZ1;
    }
    GetAxisArrowX0Geo() {
        return this.AxisArrowX0;
    }
    GetAxisArrowX1Geo() {
        return this.AxisArrowX1;
    }
}
export class GDTZAssets extends Assets {
    constructor() {
        super();
        this.FaceTextures = new FaceTexturesPackage();
        this.cubeMap = new Map();
        for (let y = CubeType.D; y >= 0; y -= 100) {
            for (let x = CubeType.R; x >= 0; x -= 10) {
                for (let z = CubeType.B; z >= 0; z -= 1) {
                    const cubeType = y + x + z;
                    const group = new THREE.Group();
                    group.position.set(0, 0, 0);
                    this.cubeMap.set(cubeType, group);
                }
            }
        }
    }
    generateFaceMeshes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            Debug.Log('Generate face meshes done.');
        });
    }
    GetBeamGeo() {
        return this.BeamGeo;
    }
    GenerateCube(position, step) {
        const lowLimit = -step / 2.0 + 0.5;
        const hightLimit = step / 2 - 0.5;
        const X = this.selectCubeType(position.x, lowLimit, hightLimit, CubeType.R, CubeType.L);
        const Y = this.selectCubeType(position.y, lowLimit, hightLimit, CubeType.D, CubeType.U);
        const Z = this.selectCubeType(position.z, lowLimit, hightLimit, CubeType.B, CubeType.F);
        const cubeType = X + Y + Z;
        const cube = this.cubeMap.get(cubeType);
        const faces = [undefined, undefined, undefined, undefined, undefined, undefined];
        let inFace;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < cube.children.length; i++) {
            switch (cube.children[i].name) {
                case 'D':
                    faces[0] = cube.children[i].clone();
                    faces[0].material = this.MatColorFace.clone();
                    break;
                case 'U':
                    faces[1] = cube.children[i].clone();
                    faces[1].material = this.MatColorFace.clone();
                    break;
                case 'R':
                    faces[2] = cube.children[i].clone();
                    faces[2].material = this.MatColorFace.clone();
                    break;
                case 'L':
                    faces[3] = cube.children[i].clone();
                    faces[3].material = this.MatColorFace.clone();
                    break;
                case 'B':
                    faces[4] = cube.children[i].clone();
                    faces[4].material = this.MatColorFace.clone();
                    break;
                case 'F':
                    faces[5] = cube.children[i].clone();
                    faces[5].material = this.MatColorFace.clone();
                    break;
                case '0':
                    inFace = cube.children[i].clone();
                    inFace.material = this.MatBlackFace.clone();
                    break;
            }
        }
        for (let i = 0; i < 6; i++) {
            if (faces[i] === undefined) {
                faces[i] = inFace;
            }
            if (faces[i] !== undefined) {
                faces[i].receiveShadow = true;
            }
        }
        const res = new AnyCubeController(position);
        let faceTextures = [];
        faceTextures = [this.FaceTextures, this.FaceTextures,
            this.FaceTextures, this.FaceTextures,
            this.FaceTextures, this.FaceTextures];
        res.AddFaces(faces, faceTextures);
        res.UpdateColor(Tools.GenerateInitColorFromPosition(position, step));
        return res;
    }
    // tslint:disable-next-line:variable-name
    selectCubeType(value, l, h, sel_l, sel_h) {
        if (value === l) {
            return sel_l;
        }
        else if (value === h) {
            return sel_h;
        }
        else {
            return 0;
        }
    }
    GenerateFillColorCube(position, step) {
        const lowLimit = -step / 2.0 + 0.5;
        const hightLimit = step / 2 - 0.5;
        const X = this.selectCubeType(position.x, lowLimit, hightLimit, CubeType.R, CubeType.L);
        const Y = this.selectCubeType(position.y, lowLimit, hightLimit, CubeType.D, CubeType.U);
        const Z = this.selectCubeType(position.z, lowLimit, hightLimit, CubeType.B, CubeType.F);
        const cubeType = X + Y + Z;
        const cube = this.cubeMap.get(cubeType);
        const faces = [undefined, undefined, undefined, undefined, undefined, undefined];
        let inFace;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < cube.children.length; i++) {
            switch (cube.children[i].name) {
                case 'D':
                    faces[0] = cube.children[i].clone();
                    faces[0].material = this.MatBlackFace.clone();
                    break;
                case 'U':
                    faces[1] = cube.children[i].clone();
                    faces[1].material = this.MatBlackFace.clone();
                    break;
                case 'R':
                    faces[2] = cube.children[i].clone();
                    faces[2].material = this.MatBlackFace.clone();
                    break;
                case 'L':
                    faces[3] = cube.children[i].clone();
                    faces[3].material = this.MatBlackFace.clone();
                    break;
                case 'B':
                    faces[4] = cube.children[i].clone();
                    faces[4].material = this.MatBlackFace.clone();
                    break;
                case 'F':
                    faces[5] = cube.children[i].clone();
                    faces[5].material = this.MatBlackFace.clone();
                    break;
                case '0':
                    inFace = cube.children[i].clone();
                    inFace.material = this.MatBlackFace.clone();
                    break;
            }
        }
        for (let i = 0; i < 6; i++) {
            if (faces[i] === undefined) {
                faces[i] = inFace;
            }
            if (faces[i] !== undefined) {
                faces[i].receiveShadow = true;
            }
        }
        let faceTextures = [];
        faceTextures = [this.FaceTextures, this.FaceTextures,
            this.FaceTextures, this.FaceTextures,
            this.FaceTextures, this.FaceTextures];
        const cubeController = new FillColorCubeController(position);
        cubeController.AddFaces(faces, faceTextures);
        return cubeController;
    }
    AddStaticMeshToCube(cube) {
        cube.add(this.CenterBall.clone());
    }
    generateMaterials() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.MatBlackFace = new THREE.MeshStandardMaterial({
                map: this.FaceTextures.GetBlack(),
                side: THREE.DoubleSide,
                roughness: 0.5,
                color: 0xffffff,
            });
            this.MatColorFace = new THREE.MeshPhongMaterial({
                map: this.FaceTextures.GetBlack(),
                side: THREE.DoubleSide,
                shininess: 0,
            });
            this.disposeOperations.push(() => {
                this.MatBlackFace.dispose();
            });
        });
    }
    loadAllModelsAndTextures() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield Promise.all([
                this.loadAllModels(),
                this.loadAllTextures(),
            ]);
        });
    }
    loadAllTextures() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            [this.FaceTextures.TexYellow,
                this.FaceTextures.TexWhite,
                this.FaceTextures.TexRed,
                this.FaceTextures.TexOrange,
                this.FaceTextures.TexBlue,
                this.FaceTextures.TexGreen,
                this.FaceTextures.TexBlack] = yield Promise.all([
                loadTexture('assets/MagicCube/CubeModels/GDTZCube/tz_yellow.png'),
                loadTexture('assets/MagicCube/CubeModels/GDTZCube/tz_white.png'),
                loadTexture('assets/MagicCube/CubeModels/GDTZCube/tz_red.png'),
                loadTexture('assets/MagicCube/CubeModels/GDTZCube/tz_orange.png'),
                loadTexture('assets/MagicCube/CubeModels/GDTZCube/tz_blue.png'),
                loadTexture('assets/MagicCube/CubeModels/GDTZCube/tz_green.png'),
                loadTexture('assets/MagicCube/CubeModels/GDTZCube/tz_black.png'),
            ]);
            this.disposeOperations.push(() => {
                this.FaceTextures.dispose();
            });
        });
    }
    loadAllModels() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            [this.BeamGeo,
                this.OriginWholeCube,
                this.AxisArrowY0,
                this.AxisArrowY1,
                this.AxisArrowZ0,
                this.AxisArrowZ1,
                this.AxisArrowX0,
                this.AxisArrowX1,
            ] = yield Promise.all([
                this.loadBeamModel(),
                this.loadBaseCube(),
                this.loadAxisArrowY0(),
                this.loadAxisArrowY1(),
                this.loadAxisArrowZ0(),
                this.loadAxisArrowZ1(),
                this.loadAxisArrowX0(),
                this.loadAxisArrowX1(),
            ]);
            this.disposeOperations.push(() => {
                this.BeamGeo.dispose();
                this.AxisArrowY0.dispose();
                this.AxisArrowY1.dispose();
                this.AxisArrowZ0.dispose();
                this.AxisArrowZ1.dispose();
                this.AxisArrowX0.dispose();
                this.AxisArrowX1.dispose();
            });
        });
    }
    loadBaseCube() {
        return new Promise((resolve, reject) => {
            fbxLoader.load('assets/MagicCube/CubeModels/GDTZCube/GDTZCube.FBX', (object) => {
                const childrenCount = object.children.length;
                const children = new Array();
                for (let i = 0; i < childrenCount; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        children.push(object.children[i].clone());
                    }
                }
                children.forEach((value, index) => {
                    value.geometry.scale(1 / 18, 1 / 18, 1 / 18);
                    if (value.name !== '0') {
                        const str = value.name.split('_', 2);
                        value.position.set(0, 0, 0);
                        value.name = str[1];
                        this.addFaceByName(str[0], value);
                        switch (value.name) {
                            case 'R':
                                value.geometry.translate(-0.01, 0, 0);
                                break;
                            case 'L':
                                value.geometry.translate(0.01, 0, 0);
                                break;
                            case 'D':
                                value.geometry.translate(0, -0.01, 0);
                                break;
                            case 'U':
                                value.geometry.translate(0, 0.01, 0);
                                break;
                            case 'F':
                                value.geometry.translate(0, 0, 0.01);
                                break;
                            case 'B':
                                value.geometry.translate(0, 0, -0.01);
                                break;
                        }
                    }
                    else {
                        this.CenterBall = value.clone();
                    }
                });
                Debug.Log('Load Base Cube', this.cubeMap);
                resolve(object);
            });
        });
    }
    addFaceByName(typeStr, face) {
        let cubeType;
        switch (typeStr) {
            case 'DLB':
                cubeType = CubeType.DLB;
                break;
            case 'DRB':
                cubeType = CubeType.DRB;
                break;
            case 'DRF':
                cubeType = CubeType.DRF;
                break;
            case 'DLF':
                cubeType = CubeType.DLF;
                break;
            case 'ULB':
                cubeType = CubeType.ULB;
                break;
            case 'URB':
                cubeType = CubeType.URB;
                break;
            case 'URF':
                cubeType = CubeType.URF;
                break;
            case 'ULF':
                cubeType = CubeType.ULF;
                break;
            case 'DB':
                cubeType = CubeType.DB;
                break;
            case 'DR':
                cubeType = CubeType.DR;
                break;
            case 'DF':
                cubeType = CubeType.DF;
                break;
            case 'DL':
                cubeType = CubeType.DL;
                break;
            case 'UB':
                cubeType = CubeType.UB;
                break;
            case 'UR':
                cubeType = CubeType.UR;
                break;
            case 'UF':
                cubeType = CubeType.UF;
                break;
            case 'UL':
                cubeType = CubeType.UL;
                break;
            case 'LB':
                cubeType = CubeType.LB;
                break;
            case 'RB':
                cubeType = CubeType.RB;
                break;
            case 'RF':
                cubeType = CubeType.RF;
                break;
            case 'LF':
                cubeType = CubeType.LF;
                break;
            case 'D':
                cubeType = CubeType.D;
                break;
            case 'U':
                cubeType = CubeType.U;
                break;
            case 'L':
                cubeType = CubeType.L;
                break;
            case 'R':
                cubeType = CubeType.R;
                break;
            case 'F':
                cubeType = CubeType.F;
                break;
            case 'B':
                cubeType = CubeType.B;
                break;
        }
        if (!this.cubeMap.has(cubeType)) {
            Debug.Log('Cube Type Error', cubeType, typeStr);
        }
        this.cubeMap.get(cubeType).add(face);
    }
    loadBeamModel() {
        return new Promise((resolve, reject) => {
            fbxLoader.load('assets/MagicCube/Effects/ETFX_AuraVertical.FBX', (object) => {
                const geo = object.children[0].geometry;
                geo.scale(1, 1, 18);
                Debug.Log('Load Beam Model');
                resolve(geo);
            });
        });
    }
    loadAxisArrowY0() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Y_0.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Y0', object);
                resolve(axisArrow);
            });
        });
    }
    loadAxisArrowY1() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Y_1.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Y1', object);
                resolve(axisArrow);
            });
        });
    }
    loadAxisArrowZ0() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Z_0.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Z0', object);
                resolve(axisArrow);
            });
        });
    }
    loadAxisArrowZ1() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_Z_1.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow Z1', object);
                resolve(axisArrow);
            });
        });
    }
    loadAxisArrowX0() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_X_0.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow X0', object);
                resolve(axisArrow);
            });
        });
    }
    loadAxisArrowX1() {
        return new Promise((resolve, reject) => {
            // const loader = new FBXLoader();
            fbxLoader.load('assets/MagicCube/Effects/AxisArrow_X_1.FBX', (object) => {
                let axisArrow;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < object.children.length; i++) {
                    if (object.children[i] instanceof THREE.Mesh) {
                        axisArrow = object.children[i].geometry.clone();
                        axisArrow.scale(1.4, 1.4, 1.4);
                        object.children[i].geometry.dispose();
                        break;
                    }
                }
                Debug.Log('Load Axis Arrow X1', object);
                resolve(axisArrow);
            });
        });
    }
    GetAxisArrowY0Geo() {
        return this.AxisArrowY0;
    }
    GetAxisArrowY1Geo() {
        return this.AxisArrowY1;
    }
    GetAxisArrowZ0Geo() {
        return this.AxisArrowZ0;
    }
    GetAxisArrowZ1Geo() {
        return this.AxisArrowZ1;
    }
    GetAxisArrowX0Geo() {
        return this.AxisArrowX0;
    }
    GetAxisArrowX1Geo() {
        return this.AxisArrowX1;
    }
}
