import * as i0 from "@angular/core";
export class BleStateService {
    constructor() {
        this.connectedDevice = null;
        this.prevConnectedDevice = null;
        this.devices = {};
        this.scanning = false;
        this.flush = 0;
    }
    /**
     * 返回是否已经连接设备
     */
    get connected() {
        return !!this.connectedDevice;
    }
}
BleStateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BleStateService_Factory() { return new BleStateService(); }, token: BleStateService, providedIn: "root" });
