import { Injectable } from '@angular/core'
import { BLE } from '@ionic-native/ble/ngx'
import { BluetoothLE } from '@ionic-native/bluetooth-le/ngx'
import { Device } from '@ionic-native/device/ngx'
import { Observable, Subject, Subscription, Subscriber } from 'rxjs'
import { BleStateService, BleDeviceInfo } from './ble-state.service';
import { filter, tap, flatMap, map, share } from 'rxjs/operators';
import { PTPV2 } from 'src/libs/ptp-v2';
import { BleService, IBle } from './ble.service';
import toArrayBuffer from 'to-arraybuffer'
import { BleConfigService } from './ble-config.service';
import { Buffer } from 'buffer'
import { sleep } from 'src/libs/utils';
import { DfuProgress, getUpdatesFromZipFileBytes, DfuTransportAnyBle, DfuOperation } from 'src/libs/nrf-dfu'
@Injectable({
  providedIn: 'root'
})
export class BleNativeService implements IBle {

  command$Subscription: Subscription;
  rotate$Subscription: Subscription;
  attitude$Subscription: Subscription;
  debug$Subscription: Subscription

  constructor(
    private bleConfigService: BleConfigService,
    private ble: BLE,
    private bluetoothle: BluetoothLE,
    private device: Device,
    private bleStateService: BleStateService,
  ) {
    this.bluetoothle.initialize()
    this.isAndroid = device.platform === 'Android'
    this.afterAndroid6 = this.isAndroid && +device.version.split('.')[0] >= 6
  }

  private ptpV2: PTPV2 = null

  private isAndroid: boolean
  private afterAndroid6: boolean

  private _command$: Subject<Buffer>
  private _rotate$: Subject<Buffer>
  private _attitude$: Subject<Buffer>

  private _otaProgress$ = new Subject<DfuProgress>()

  public get otaProgress$(): Observable<DfuProgress> {
    return this._otaProgress$
  }

  public get command$(): Observable<Buffer> {
    return this._command$
  }

  public get rotate$(): Observable<Buffer> {
    return this._rotate$
  }

  public get attitude$(): Observable<Buffer> {
    return this._attitude$
  }


  private bleConnection$Subscription: Subscription

  public async checkPermission(): Promise<{ isEnabled: boolean, hasPermission: boolean, isLocationEnabled: boolean }> {
    let isEnabled = true
    try {
      await this.ble.isEnabled()
    } catch (e) {
      isEnabled = false
    }
    return {
      isEnabled,
      hasPermission: !this.isAndroid || (this.isAndroid && !this.afterAndroid6)
        || (await this.bluetoothle.hasPermission()).hasPermission,
      isLocationEnabled: !this.isAndroid || (this.isAndroid && !this.afterAndroid6)
        || (await this.bluetoothle.isLocationEnabled()).isLocationEnabled
    }
  }

  public async requestPermission(): Promise<{ isEnabled: boolean, hasPermission: boolean, isLocationEnabled: boolean }> {
    let { isEnabled, hasPermission, isLocationEnabled } = await this.checkPermission()
    if (!isEnabled) {
      await this.ble.enable()
    }
    if (!hasPermission) {
      await this.bluetoothle.requestPermission()
    }
    if (!isLocationEnabled) {
      await this.bluetoothle.requestLocation()
    }
    return await this.checkPermission()
  }

  public async startScan(startWith: string): Promise<Observable<BleDeviceInfo>> {
    this.bleStateService.devices = {}

    try {
      return this.ble.startScan([]).pipe(
        filter(i => i.name && i.name.match(new RegExp(`^${startWith}`))),
        tap(i => {
          this.bleStateService.devices[i.id] = i
        }))
    } finally {
      // 为了让流已经返回后才置scanning为 true
      this.bleStateService.scanning = true
    }
  }

  public async stopScan(): Promise<void> {
    this.bleStateService.scanning = false
    await this.ble.stopScan()
  }

  public async connect(deviceId: string, enableDebug: boolean): Promise<Observable<Buffer>> {
    return new Promise<Observable<Buffer>>((resolve, reject) => {

      // 如果已经连接了设备，则将其断开
      if (this.bleStateService.connectedDevice) {
        this.disconnect(this.bleStateService.connectedDevice.id)
      }

      this.bleConnection$Subscription = this.ble.connect(deviceId).subscribe(
        async i => {
          this._command$ = new Subject<Buffer>()
          this._rotate$ = new Subject<Buffer>()
          this._attitude$ = new Subject<Buffer>()
          this.bleStateService.connectedDevice = this.bleStateService.devices[deviceId]
          this.ptpV2 = new PTPV2()

          this.command$Subscription = this.ble.startNotification(
            deviceId,
            this.bleConfigService.DATA_SERVICE_UUID.toString(16),
            this.bleConfigService.COMMAND_READ_CHARACTERISTIC_UUID.toString(16)
          ).pipe(
            flatMap(i => {
              this.ptpV2.input(Buffer.from(i))
              const res = []
              let recv: Buffer | null
              while (recv = this.ptpV2.receive()) {
                res.push(recv)
              }
              return res
            })
          ).subscribe(i => {
            this._command$.next(i)
          }, err => {
            this._command$.error(err)
          }, () => {
            this._command$.complete()
          })

          this.rotate$Subscription = this.ble.startNotification(
            deviceId,
            this.bleConfigService.DATA_SERVICE_UUID.toString(16),
            this.bleConfigService.ROTATE_READ_CHARACTERISTIC_UUID.toString(16)
          ).pipe(
            map(i => Buffer.from(i))
          ).subscribe(i => {
            this._rotate$.next(i)
          }, err => {
            console.log(err)
          })

          this.attitude$Subscription = this.ble.startNotification(
            deviceId,
            this.bleConfigService.DATA_SERVICE_UUID.toString(16),
            this.bleConfigService.ATTITUDE_READ_CHARACTERISTIC_UUID.toString(16)
          ).pipe(
            map(i => Buffer.from(i))
          ).subscribe(i => {
            this._attitude$.next(i)
          }, err => {
            console.log(err)
          })

          if (enableDebug) {
            this.debug$Subscription = this.ble.startNotification(
              deviceId,
              this.bleConfigService.DATA_SERVICE_UUID.toString(16),
              this.bleConfigService.DEBUG_CHARACTERISTIC_UUID.toString(16)
            ).pipe(
              map(i => Buffer.from(i))
            ).subscribe(i => {
              console.log(i.toString())
            }, err => {
              console.log(err)
            })
          }


          resolve(this._command$)
        },
        err => {
          this._command$.error(err)
          console.log(err)
          this.clearConnection()
        },
        () => {
          console.log('connection finish')
          this.clearConnection()
        })
    })
  }

  private clearConnection() {
    this.ptpV2 = null
    this.bleStateService.connectedDevice = null
    if (this.bleConnection$Subscription) {
      this.bleConnection$Subscription.unsubscribe()
      this.bleConnection$Subscription = null
    }
    if (this.command$Subscription) {
      this.command$Subscription.unsubscribe()
      this.command$Subscription = null
    }
    if (this.rotate$Subscription) {
      this.rotate$Subscription.unsubscribe()
      this.rotate$Subscription = null
    }
    if (this.attitude$Subscription) {
      this.attitude$Subscription.unsubscribe()
      this.attitude$Subscription = null
    }
    if (this.debug$Subscription) {
      this.debug$Subscription.unsubscribe()
      this.debug$Subscription = null
    }
    this._command$ = this._rotate$ = this._attitude$ = null
  }

  public async disconnect(deviceId: string): Promise<void> {
    this.clearConnection()
    await this.ble.disconnect(deviceId)
  }

  public async send(buff: Buffer): Promise<void> {
    if (!this.bleStateService.connected) {
      throw new Error('还未连接设备，请先连接设备再发送数据')
    }
    this.ptpV2.send(buff)
    let outputBuff: Buffer | null
    while (outputBuff = this.ptpV2.output()) {
      await this.ble.writeWithoutResponse(
        this.bleStateService.connectedDevice.id,
        this.bleConfigService.DATA_SERVICE_UUID.toString(16),
        this.bleConfigService.COMMAND_WRITE_CHARACTERISTIC_UUID.toString(16),
        toArrayBuffer(outputBuff)
      )
    }
  }

  public async ota(otaFileBuffer: Buffer, mtu: number, prn: number) {
    const receiveData$ = this.ble.startNotification(
      this.bleStateService.connectedDevice.id,
      this.bleConfigService.OTA_SERVICE_UUID.toString(16),
      this.bleConfigService.DFU_CONTROL_CHARACTERISTIC_UUID
    ).pipe(
      map(i => Buffer.from(i))
    )

    const prepareBle = async () => {
      await sleep(500)
    }

    const writeCommandImpl = async (buff: Buffer) => {
      await this.ble.write(
        this.bleStateService.connectedDevice.id,
        this.bleConfigService.OTA_SERVICE_UUID.toString(16),
        this.bleConfigService.DFU_CONTROL_CHARACTERISTIC_UUID,
        toArrayBuffer(buff)
      )
    }
    const writeDataImpl = async (buff: Buffer) => {
      await this.ble.writeWithoutResponse(
        this.bleStateService.connectedDevice.id,
        this.bleConfigService.OTA_SERVICE_UUID.toString(16),
        this.bleConfigService.DFU_PACKET_CHARACTERISTIC_UUID,
        toArrayBuffer(buff)
      )
    }

    const onProgress = (stage: number, sendBytes: number, totalBytes: number) => {
      this._otaProgress$.next({ stage, sendBytes, totalBytes })
    }
    const updates = await getUpdatesFromZipFileBytes(otaFileBuffer)
    const bleTransport = new DfuTransportAnyBle(prepareBle, writeCommandImpl, writeDataImpl, receiveData$, mtu, prn, onProgress)

    const dfu = new DfuOperation(updates, bleTransport);
    await dfu.start(true)
  }

  public async requestMtu(mtuSize?: number): Promise<number> {
    const THRESHOLD = 10
    if (mtuSize) {
      this.ble.requestMtu(this.bleStateService.connectedDevice.id, mtuSize)
      return mtuSize
    } else {
      // 通过二分法，寻找最大 Mtu
      // 因为 ble.requestMtu 始终返回true而暂时无法使用
      let min = 23, max = 517
      while (max - min > THRESHOLD) {
        const mid = Math.ceil((min + max) / 2)
        let requestMtuSuccess = true
        try {
          console.log(await this.ble.requestMtu(this.bleStateService.connectedDevice.id, mid))
        } catch (err) {
          requestMtuSuccess = false
        }
        console.log(`mtu: ${mid}, ${requestMtuSuccess}`)
        if (requestMtuSuccess) {
          min = mid
        } else {
          max = mid
        }
      }
      return min
    }
  }
}
