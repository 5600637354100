import * as tslib_1 from "tslib";
import { BLE } from '@ionic-native/ble/ngx';
import { BluetoothLE } from '@ionic-native/bluetooth-le/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Observable, Subject, Subscription } from 'rxjs';
import { BleStateService } from './ble-state.service';
import { filter, tap, flatMap, map } from 'rxjs/operators';
import { PTPV2 } from 'src/libs/ptp-v2';
import { IBle } from './ble.service';
import toArrayBuffer from 'to-arraybuffer';
import { BleConfigService } from './ble-config.service';
import { Buffer } from 'buffer';
import { sleep } from 'src/libs/utils';
import { getUpdatesFromZipFileBytes, DfuTransportAnyBle, DfuOperation } from 'src/libs/nrf-dfu';
import * as i0 from "@angular/core";
import * as i1 from "./ble-config.service";
import * as i2 from "@ionic-native/ble/ngx/index";
import * as i3 from "@ionic-native/bluetooth-le/ngx/index";
import * as i4 from "@ionic-native/device/ngx/index";
import * as i5 from "./ble-state.service";
export class BleNativeService {
    constructor(bleConfigService, ble, bluetoothle, device, bleStateService) {
        this.bleConfigService = bleConfigService;
        this.ble = ble;
        this.bluetoothle = bluetoothle;
        this.device = device;
        this.bleStateService = bleStateService;
        this.ptpV2 = null;
        this._otaProgress$ = new Subject();
        this.bluetoothle.initialize();
        this.isAndroid = device.platform === 'Android';
        this.afterAndroid6 = this.isAndroid && +device.version.split('.')[0] >= 6;
    }
    get otaProgress$() {
        return this._otaProgress$;
    }
    get command$() {
        return this._command$;
    }
    get rotate$() {
        return this._rotate$;
    }
    get attitude$() {
        return this._attitude$;
    }
    checkPermission() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let isEnabled = true;
            try {
                yield this.ble.isEnabled();
            }
            catch (e) {
                isEnabled = false;
            }
            return {
                isEnabled,
                hasPermission: !this.isAndroid || (this.isAndroid && !this.afterAndroid6)
                    || (yield this.bluetoothle.hasPermission()).hasPermission,
                isLocationEnabled: !this.isAndroid || (this.isAndroid && !this.afterAndroid6)
                    || (yield this.bluetoothle.isLocationEnabled()).isLocationEnabled
            };
        });
    }
    requestPermission() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let { isEnabled, hasPermission, isLocationEnabled } = yield this.checkPermission();
            if (!isEnabled) {
                yield this.ble.enable();
            }
            if (!hasPermission) {
                yield this.bluetoothle.requestPermission();
            }
            if (!isLocationEnabled) {
                yield this.bluetoothle.requestLocation();
            }
            return yield this.checkPermission();
        });
    }
    startScan(startWith) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.bleStateService.devices = {};
            try {
                return this.ble.startScan([]).pipe(filter(i => i.name && i.name.match(new RegExp(`^${startWith}`))), tap(i => {
                    this.bleStateService.devices[i.id] = i;
                }));
            }
            finally {
                // 为了让流已经返回后才置scanning为 true
                this.bleStateService.scanning = true;
            }
        });
    }
    stopScan() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.bleStateService.scanning = false;
            yield this.ble.stopScan();
        });
    }
    connect(deviceId, enableDebug) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                // 如果已经连接了设备，则将其断开
                if (this.bleStateService.connectedDevice) {
                    this.disconnect(this.bleStateService.connectedDevice.id);
                }
                this.bleConnection$Subscription = this.ble.connect(deviceId).subscribe((i) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this._command$ = new Subject();
                    this._rotate$ = new Subject();
                    this._attitude$ = new Subject();
                    this.bleStateService.connectedDevice = this.bleStateService.devices[deviceId];
                    this.ptpV2 = new PTPV2();
                    this.command$Subscription = this.ble.startNotification(deviceId, this.bleConfigService.DATA_SERVICE_UUID.toString(16), this.bleConfigService.COMMAND_READ_CHARACTERISTIC_UUID.toString(16)).pipe(flatMap(i => {
                        this.ptpV2.input(Buffer.from(i));
                        const res = [];
                        let recv;
                        while (recv = this.ptpV2.receive()) {
                            res.push(recv);
                        }
                        return res;
                    })).subscribe(i => {
                        this._command$.next(i);
                    }, err => {
                        this._command$.error(err);
                    }, () => {
                        this._command$.complete();
                    });
                    this.rotate$Subscription = this.ble.startNotification(deviceId, this.bleConfigService.DATA_SERVICE_UUID.toString(16), this.bleConfigService.ROTATE_READ_CHARACTERISTIC_UUID.toString(16)).pipe(map(i => Buffer.from(i))).subscribe(i => {
                        this._rotate$.next(i);
                    }, err => {
                        console.log(err);
                    });
                    this.attitude$Subscription = this.ble.startNotification(deviceId, this.bleConfigService.DATA_SERVICE_UUID.toString(16), this.bleConfigService.ATTITUDE_READ_CHARACTERISTIC_UUID.toString(16)).pipe(map(i => Buffer.from(i))).subscribe(i => {
                        this._attitude$.next(i);
                    }, err => {
                        console.log(err);
                    });
                    if (enableDebug) {
                        this.debug$Subscription = this.ble.startNotification(deviceId, this.bleConfigService.DATA_SERVICE_UUID.toString(16), this.bleConfigService.DEBUG_CHARACTERISTIC_UUID.toString(16)).pipe(map(i => Buffer.from(i))).subscribe(i => {
                            console.log(i.toString());
                        }, err => {
                            console.log(err);
                        });
                    }
                    resolve(this._command$);
                }), err => {
                    this._command$.error(err);
                    console.log(err);
                    this.clearConnection();
                }, () => {
                    console.log('connection finish');
                    this.clearConnection();
                });
            });
        });
    }
    clearConnection() {
        this.ptpV2 = null;
        this.bleStateService.connectedDevice = null;
        if (this.bleConnection$Subscription) {
            this.bleConnection$Subscription.unsubscribe();
            this.bleConnection$Subscription = null;
        }
        if (this.command$Subscription) {
            this.command$Subscription.unsubscribe();
            this.command$Subscription = null;
        }
        if (this.rotate$Subscription) {
            this.rotate$Subscription.unsubscribe();
            this.rotate$Subscription = null;
        }
        if (this.attitude$Subscription) {
            this.attitude$Subscription.unsubscribe();
            this.attitude$Subscription = null;
        }
        if (this.debug$Subscription) {
            this.debug$Subscription.unsubscribe();
            this.debug$Subscription = null;
        }
        this._command$ = this._rotate$ = this._attitude$ = null;
    }
    disconnect(deviceId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.clearConnection();
            yield this.ble.disconnect(deviceId);
        });
    }
    send(buff) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.bleStateService.connected) {
                throw new Error('还未连接设备，请先连接设备再发送数据');
            }
            this.ptpV2.send(buff);
            let outputBuff;
            while (outputBuff = this.ptpV2.output()) {
                yield this.ble.writeWithoutResponse(this.bleStateService.connectedDevice.id, this.bleConfigService.DATA_SERVICE_UUID.toString(16), this.bleConfigService.COMMAND_WRITE_CHARACTERISTIC_UUID.toString(16), toArrayBuffer(outputBuff));
            }
        });
    }
    ota(otaFileBuffer, mtu, prn) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const receiveData$ = this.ble.startNotification(this.bleStateService.connectedDevice.id, this.bleConfigService.OTA_SERVICE_UUID.toString(16), this.bleConfigService.DFU_CONTROL_CHARACTERISTIC_UUID).pipe(map(i => Buffer.from(i)));
            const prepareBle = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                yield sleep(500);
            });
            const writeCommandImpl = (buff) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                yield this.ble.write(this.bleStateService.connectedDevice.id, this.bleConfigService.OTA_SERVICE_UUID.toString(16), this.bleConfigService.DFU_CONTROL_CHARACTERISTIC_UUID, toArrayBuffer(buff));
            });
            const writeDataImpl = (buff) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                yield this.ble.writeWithoutResponse(this.bleStateService.connectedDevice.id, this.bleConfigService.OTA_SERVICE_UUID.toString(16), this.bleConfigService.DFU_PACKET_CHARACTERISTIC_UUID, toArrayBuffer(buff));
            });
            const onProgress = (stage, sendBytes, totalBytes) => {
                this._otaProgress$.next({ stage, sendBytes, totalBytes });
            };
            const updates = yield getUpdatesFromZipFileBytes(otaFileBuffer);
            const bleTransport = new DfuTransportAnyBle(prepareBle, writeCommandImpl, writeDataImpl, receiveData$, mtu, prn, onProgress);
            const dfu = new DfuOperation(updates, bleTransport);
            yield dfu.start(true);
        });
    }
    requestMtu(mtuSize) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const THRESHOLD = 10;
            if (mtuSize) {
                this.ble.requestMtu(this.bleStateService.connectedDevice.id, mtuSize);
                return mtuSize;
            }
            else {
                // 通过二分法，寻找最大 Mtu
                // 因为 ble.requestMtu 始终返回true而暂时无法使用
                let min = 23, max = 517;
                while (max - min > THRESHOLD) {
                    const mid = Math.ceil((min + max) / 2);
                    let requestMtuSuccess = true;
                    try {
                        console.log(yield this.ble.requestMtu(this.bleStateService.connectedDevice.id, mid));
                    }
                    catch (err) {
                        requestMtuSuccess = false;
                    }
                    console.log(`mtu: ${mid}, ${requestMtuSuccess}`);
                    if (requestMtuSuccess) {
                        min = mid;
                    }
                    else {
                        max = mid;
                    }
                }
                return min;
            }
        });
    }
}
BleNativeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BleNativeService_Factory() { return new BleNativeService(i0.ɵɵinject(i1.BleConfigService), i0.ɵɵinject(i2.BLE), i0.ɵɵinject(i3.BluetoothLE), i0.ɵɵinject(i4.Device), i0.ɵɵinject(i5.BleStateService)); }, token: BleNativeService, providedIn: "root" });
