<ion-list class="ble-list">
  <ng-container *ngFor="let device of bleScan$ | async ">
    <ion-item (click)="connect(device)" class="notCntDevices devices"
      *ngIf="!bleStateService.connectedDevice || (bleStateService.connectedDevice && bleStateService.connectedDevice.id != device.id)">
      <span style="margin-left: 16px;color: #a5a5a5;">{{device.name}}</span>
    </ion-item>
  </ng-container>
</ion-list>


<div mat-dialog-actions>
  <button mat-button [disabled]="bleStateService.scanning" (click)="startScan()">扫描</button>
  <button mat-button (click)="close()">取消</button>
</div>