import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './components/material.module';
import { CustomizedComponentModule } from './components/customized-component.module';
import { BLE } from '@ionic-native/ble/ngx';
import { BluetoothLE } from '@ionic-native/bluetooth-le/ngx';
import { Device } from '@ionic-native/device/ngx';
import { BleDialogComponent } from './components/ble-dialog/ble-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    BleDialogComponent
  ],
  entryComponents: [
    BleDialogComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    MaterialModule,
    CustomizedComponentModule,
    BrowserAnimationsModule,
    AppRoutingModule],
  providers: [
    BLE,
    BluetoothLE,
    Device,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
