import * as i0 from "@angular/core";
export class BleConfigService {
    constructor() {
        this.DATA_SERVICE_UUID = 0x1000;
        this.COMMAND_WRITE_CHARACTERISTIC_UUID = 0x1001;
        this.COMMAND_READ_CHARACTERISTIC_UUID = 0x1002;
        this.ROTATE_READ_CHARACTERISTIC_UUID = 0x1003;
        this.ATTITUDE_READ_CHARACTERISTIC_UUID = 0x1004;
        this.DEBUG_CHARACTERISTIC_UUID = 0x1005;
        this.OTA_SERVICE_UUID = 0xfe59;
        this.DFU_CONTROL_CHARACTERISTIC_UUID = '8EC90001-F315-4F60-9FB8-838830DAEA50'.toLocaleLowerCase();
        this.DFU_PACKET_CHARACTERISTIC_UUID = '8EC90002-F315-4F60-9FB8-838830DAEA50'.toLocaleLowerCase();
    }
}
BleConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BleConfigService_Factory() { return new BleConfigService(); }, token: BleConfigService, providedIn: "root" });
